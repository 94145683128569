import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import { trackerSelector, trackerSlotDataSelector } from '@selectors';
import {
  addSlot,
  removeSlot,
  setSlotValue,
  setTrackerAction,
} from '@slices/trackerSlices';
import { runAction, validateAction } from '@slices/terminalSlices';
import { Slot as ISlot } from '@projectTypes/Tracker';

import Slot from './Slot';

const TrackerModal = () => {
  const dispatch = useDispatch();
  const trackerState = useSelector(trackerSelector);
  const trackerData = useSelector(trackerSlotDataSelector);

  const closeModal = () => {
    dispatch(setTrackerAction(null));
  };

  const isRun = trackerState.trackerAction.actionType === 'run';

  const onSubmit = () => {
    const actionProps = {
      ...trackerState.trackerAction,
      tracker: trackerData,
    };
    if (isRun) {
      dispatch(runAction(actionProps));
    } else {
      dispatch(validateAction(actionProps));
    }
    closeModal();
  };

  return (
    <Modal isOpen onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tracker slots</ModalHeader>
        <ModalBody>
          <VStack spacing="10px">
            <form onSubmit={onSubmit}>
              {trackerState.slots.map(
                (slot, index) =>
                  !slot.hidden && (
                    <Slot
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      slot={slot}
                      onChange={(slot: ISlot) =>
                        dispatch(setSlotValue({ index, slot }))
                      }
                      removeSlot={() => dispatch(removeSlot(index))}
                    />
                  ),
              )}
              <input
                type="submit"
                style={{
                  position: 'absolute',
                  left: '-9999px',
                  width: '1px',
                  height: '1px',
                }}
                tabIndex={-1}
              />
            </form>
            <Button
              onClick={() => dispatch(addSlot())}
              bg="primary"
              color="white"
            >
              Add slot
            </Button>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={onSubmit}
            size="lg"
            bg="primary"
            color="white"
            variant="ghost"
          >
            {isRun ? 'Run action' : 'Validate action'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TrackerModal;
