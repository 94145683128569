export default {
  robot: {
    setLedStatic: true,
    setLed: true,
    move: true,
    setLedRotationSpeed: true,
    setHeight: true,
    tilt: true,
    rotate: true,
    getTouchPattern: true,
    setLedBlinkingSpeed: true,
  },
  delay: true,
  LED_R1: true,
  LED_R2: true,
  LED_R3: true,
  LED_L1: true,
  LED_L2: true,
  LED_L3: true,
  hexapod: {
    init: true,
  },
  ROBOT_USER_MODE: true,
  FORWARD: true,
  BACKWARD: true,
  setup: true,
  loop: true,
};

export const types = {
  LED_R1: 'Constant',
  LED_R2: 'Constant',
  LED_R3: 'Constant',
  LED_L1: 'Constant',
  LED_L2: 'Constant',
  LED_L3: 'Constant',
  ROBOT_USER_MODE: 'Constant',
  FORWARD: 'Constant',
  BACKWARD: 'Constant',
};
