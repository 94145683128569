import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Flex,
  Spinner,
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
} from '@chakra-ui/react';
import { Global, css } from '@emotion/react';

import { terminalMessageSelector, terminalSelector } from '@selectors';

import Message from './Message';
import ChatInput from './ChatInput';

const messageStyles = css`
  #message_container {
    ul,
    ol {
      margin-left: 12px;
    }

    h1 {
      font-size: 2em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.75em;
      font-weight: bold;
    }
    h3 {
      font-size: 1.5em;
      font-weight: bold;
    }
    h4 {
      font-size: 1.25em;
    }
    h5 {
      font-size: 1em;
    }
    h6 {
      font-size: 0.75em;
    }
  }
`;

interface ChatbotContainerProps {
  isPublic?: boolean;
}

const ChatbotContainer = (props: ChatbotContainerProps) => {
  const { isPublic } = props;
  const lastElement = useRef<any>(null);
  const messages = useSelector(terminalMessageSelector);
  const terminalState = useSelector(terminalSelector);
  const [isBooting, setIsBooting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    lastElement.current.scrollIntoView();
  }, [terminalState.messages.length]);

  useEffect(() => {
    if (terminalState.isBooting) {
      setIsBooting(true);
      setTimeout(() => {
        setShowInfo(true);
      }, 10000);
    } else {
      setIsBooting(false);
      setShowInfo(false);
    }
  }, [terminalState.isBooting]);

  return (
    <Box position="relative" width="100%" minH="100%">
      {isBooting && (
        <Box
          position="absolute"
          zIndex={1}
          background="#00000060"
          left="0px"
          right="0px"
          top="0px"
          bottom="0px"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Alert p="20px" status="warning" opacity={showInfo ? 1 : 0}>
            <AlertIcon />
            <AlertTitle mr={2}>Please wait!</AlertTitle>
            <AlertDescription fontSize="16px">
              This might take up to 1 minute.
            </AlertDescription>
          </Alert>
          <Spinner
            width="150px"
            height="150px"
            thickness="8px"
            color="primary"
          />
          <Box />
        </Box>
      )}
      <Global styles={messageStyles} />
      <Flex
        id="message_container"
        flexDirection="column-reverse"
        h="100%"
        w="100%"
        style={{ scrollbarWidth: 'none' }}
      >
        <Box h="50px" ref={lastElement} />
        {terminalState.isWaitingResponse && <Spinner size="md" />}
        {[...messages].reverse().map((message, index) => (
          <Message
            key={message.date}
            message={{ ...message, debug: isPublic ? null : message.debug }}
            isLastMessage={index === 0}
            isChat
          />
        ))}
        <Box h="40px" />
      </Flex>
      <Box
        position="absolute"
        bottom="0px"
        w="100%"
        background="#FFF"
        boxShadow="0px 0 10px rgb(0 0 0 / 80%)"
      >
        <ChatInput />
        <Divider />
      </Box>
    </Box>
  );
};

export default ChatbotContainer;
