export default {
  print: true,
  input: true,
  while: true,
  for: true,
  len: true,
  str: true,
  if: true,
  elif: true,
  else: true,
  list: true,
  enumerate: true,
  range: true,
  class: true,
  or: true,
  and: true,
  def: true,
  tracker: {
    get_slot: true,
  },
  dispatcher: {
    utter_message: true,
  },
  requests: {
    get: true,
  },
  SlotSet: true,
  __init__: true,
  __main__: true,
  stemi: {
    mqtt: true,
    digitalio: true,
    pulseio: true,
    analogio: true,
    barometer: true,
    display: true,
    robot: true,
  },
  mqtt: {
    post_to_chat: true,
  },
};

export const types = {
  for: 'Keyword',
  while: 'Keyword',
  if: 'Keyword',
  elif: 'Keyword',
  else: 'Keyword',
  class: 'Keyword',
  or: 'Keyword',
  and: 'Keyword',
};
