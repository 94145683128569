import React from 'react';
import {
  IconButton,
  HStack,
  Text,
  Tooltip,
  Button,
  VStack,
} from '@chakra-ui/react';
import { ImArrowRight } from 'react-icons/im';
import { IoMdOpen } from 'react-icons/io';
import CommitType from '@projectTypes/Commit';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { commitSelector } from '@selectors';

interface CommitProps {
  index: number;
  commit: CommitType;
  currentCommit: boolean;
  onSelect: (_index: number) => void;
  onLoadCommit: (_index: number) => void;
  onPush: (_index: number) => void;
}

const Commit = (props: CommitProps) => {
  const {
    index,
    commit,
    currentCommit,
    onSelect,
    onLoadCommit,
    onPush,
  } = props;
  let message = '';
  const { diffIndex, showDiff } = useSelector(commitSelector);
  if (commit.message.length > 0) {
    if (commit.message.indexOf('\n') !== -1) {
      message = `${commit.message.split('\n')[0]}...`;
    } else {
      message = commit.message;
    }
  } else {
    message = '[no message]';
  }

  const selected = index === diffIndex && showDiff;

  const onClick = (e) => {
    e.preventDefault();
    onSelect(index);
  };

  const onLoad = (e: React.MouseEvent) => {
    e.stopPropagation();
    onLoadCommit(index);
  };

  return (
    <Button
      data-cy="commitButton"
      h="max-content"
      variant="outline"
      className={classNames({
        selected,
      })}
      sx={{
        '&': {
          width: '100%',
          borderRadius: '4px',
          background: '#37373d',
        },
        '&:hover': {
          background: '#7B7B89',
        },
        '&.selected': {
          background: '#37373d',
          color: 'white',
        },
        '&.selected .btn': {
          background: '#3155f6',
          color: '#243473',
        },
        '& .btn:hover': {
          background: '#243473',
          color: '#7b7b89',
        },
        '&:not(.selected)': {
          color: 'unset',
        },
        '&.selected:hover': {
          background: '#243473',
          color: '#243473',
        },
        '& .commit-nm': {
          color: 'white',
        },
        '&.selected .commit-nm': {
          color: 'white',
        },
        '&.selected:hover .commit-nm': {
          color: 'white',
        },
        '& .commit-dt': {
          color: 'gray',
          fontSize: 12,
        },
        '& .commit-msg': {
          flexGrow: 1,
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
          color: 'white',
        },
        '& .commit-actions': {
          color: 'unset',
        },
        '&:focus': {
          zIndex: 1,
        },
      }}
      onClick={onClick}
    >
      <HStack w="full" justifyContent="space-between">
        <VStack py={2}>
          <HStack w="full">
            <Text className="commit-nm">#{index + 1}</Text>
            <Text data-cy="commitMsg" className="commit-msg">
              {message}
            </Text>
          </HStack>
          <HStack w="full">
            <Text className="commit-dt">
              {new Date((commit.createdAt as any) as string).toLocaleString()}
            </Text>
          </HStack>
        </VStack>
        <HStack>
          <Tooltip
            shouldWrapChildren
            label={
              currentCommit ? 'Code already on device' : 'Push commit to device'
            }
            color="white"
          >
            <span />
          </Tooltip>
          <Tooltip shouldWrapChildren label="Load commit" color="white">
            <IconButton
              className="btn"
              aria-label="Load commit"
              bg="#3155f6"
              icon={<IoMdOpen className="icon" color="white" />}
              onClick={onLoad}
            />
          </Tooltip>
        </HStack>
      </HStack>
    </Button>
  );
};

export default Commit;
