import utilService from '@services/UtilService';
import delay from '@helpers/delay';
import { FileContentError } from '@projectTypes/Error';

import getYamlErrors from './getYamlErrors';
import getPythonErrors from './getPythonErrors';

const getFileContentError = async (
  content: string,
  contentType: string,
): Promise<FileContentError | null> => {
  await delay(0);
  switch (contentType) {
    case 'yaml':
    case 'yml':
      return getYamlErrors(content);
    case 'py':
    case 'python':
      return getPythonErrors(content);
    default:
      return null;
  }
};

export default getFileContentError;
