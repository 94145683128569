import React from 'react';
import { Box } from '@chakra-ui/react';
import ProjectNavigation from './ProjectNavigation';
import ProjectTree from './ProjectTree';


const ProjectContainer = () => {

  return (
    <Box pt="0px">
      <ProjectNavigation />
      <ProjectTree />
    </Box>
  );
};

export default ProjectContainer;
