import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { leftSidebarSelector, rightSidebarSelector } from '@selectors';
import config from '@config';

export default function useWindowSize() {
  const leftSidebarOpen = useSelector(leftSidebarSelector);
  const rightSidebarOpen = useSelector(rightSidebarSelector);
  const [size, setSize] = useState({ width: 0, height: 0 });

  function updateSize() {
    let width = window.innerWidth + 1;
    if (leftSidebarOpen) {
      width -= config.leftSidebarWidth;
    }
    if (rightSidebarOpen) {
      width -= config.rightSidebarWidth;
    }

    setSize({ width, height: window.innerHeight });
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => updateSize(), [leftSidebarOpen, rightSidebarOpen]);

  return size;
}
