import YAML from 'js-yaml';

import { FileContentError } from '@projectTypes/Error';

interface YamlError {
  mark: YamlErrorMark;
  message: string;
  name: string;
  reason: string;
}
interface YamlErrorMark {
  buffer: string;
  snippet: string;
  column: number;
  line: number;
  position: number;
}

const getYamlError = (content: string): FileContentError | null => {
  try {
    YAML.load(content);
    return null;
  } catch (e) {
    return {
      ...(e as YamlError),
      ...(e as YamlError).mark,
    };
  }
};

export default getYamlError;
