import React from 'react';
import { IconButton, HStack, Input, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { Slot as ISlot } from '@projectTypes/Tracker';

interface SlotProps {
  slot: ISlot;
  onChange: (slot: ISlot) => void;
  removeSlot: () => void;
}

const Slot = (props: SlotProps) => {
  const { slot, onChange, removeSlot } = props;

  return (
    <HStack mb="14px">
      <Input
        value={slot.key}
        onChange={(event) =>
          onChange({ key: event.target.value, value: slot.value })
        }
        placeholder="key"
      />
      <Text> : </Text>
      <Input
        value={slot.value}
        onChange={(event) =>
          onChange({ key: slot.key, value: event.target.value })
        }
        placeholder="value"
      />
      <IconButton
        aria-label="Close"
        bg="secondary"
        icon={<CloseIcon color="white" />}
        onClick={removeSlot}
      />
    </HStack>
  );
};

export default Slot;
