import React from 'react';
import { Text } from '@chakra-ui/react';

import Project from '@projectTypes/Project';
import timeAgo from '@helpers/timeAgo';
import { ItemButton, ItemButtonProps } from './ItemButton';

interface ProjectSummaryProps extends ItemButtonProps {
  project: Project;
  hasMultiYear: boolean;
}

const ProjectSummary: React.FC<ProjectSummaryProps> = (props) => {
  const { project, selected, hasMultiYear } = props;

  return (
    <ItemButton {...props}>
      <Text fontSize="lg" textAlign="left" whiteSpace="initial">
        {hasMultiYear && `[${project.yearId}] `}
        {project.name}
      </Text>

      <Text fontSize="xs" color={selected ? '#ccc' : 'GrayText'}>
        {timeAgo(new Date((project.lastFileUpdatedAt as unknown) as string))}
      </Text>
    </ItemButton>
  );
};

export default ProjectSummary;
