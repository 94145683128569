import { useEffect, useRef } from 'react';

import useDispatch from '@hooks/useDispatch';
import { setSlots, setTrackerAction } from '@slices/trackerSlices';
import { setFileHasError } from '@slices/fileSlices';
import File from '@projectTypes/File';

import useCodeError from './useCodeError';
import useMonacoEditorGroupEdit from './useMonacoEditorGroupEdit';

import cpp, { types as cppTypes } from '../languages/cpp';
import py, { types as pyTypes } from '../languages/py';

let isAutocompleteSetup = false;

const alpha = Array.from(Array(26))
  .map((e, i) => i + 65)
  .map((x) => String.fromCharCode(x));
const triggerCharacters = [
  ...alpha,
  ...alpha.map((a) => a.toUpperCase()),
  ...Array.from({ length: 10 }, (_, i) => i.toString()),
];

const setupMainLanguageSuggestions = (
  monaco: any,
  languageName: string,
  language: Record<string, any>,
  types: Record<string, string>,
) => {
  monaco.languages.registerCompletionItemProvider(
    languageName,
    {
      triggerCharacters,
      provideCompletionItems(model, position) {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };
        return {
          suggestions: Object.entries(language).map(([item, value]) => ({
            label: item,
            documentation: item,
            insertText: item,
            kind:
              monaco.languages.CompletionItemKind[
                types[item] || (value === true ? 'Function' : 'Class')
              ],
            range,
          })),
        };
      },
    },
  );
};

const setupSubLanguageSuggestions = (
  monaco: any,
  languageName: string,
  types: Record<string, string>,
  prefixWords: string[],
  suggestionWords: string[],
) => {
  monaco.languages.registerCompletionItemProvider(languageName, {
    triggerCharacters: ['.'],
    provideCompletionItems(model, position) {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });
      const parts = textUntilPosition.split(' ');
      const textToTest = parts[parts.length - 1];
      if (!textToTest.startsWith(prefixWords.join('.'))) {
        return { suggestions: [] };
      }
      if (
        suggestionWords.reduce((acc, item) => {
          if (textToTest.startsWith([...prefixWords, item].join('.'))) {
            return true;
          }

          return acc;
        }, false)
      ) {
        return { suggestions: [] };
      }

      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      return {
        suggestions: suggestionWords.map((item) => ({
          label: item,
          documentation: item,
          insertText: item,
          kind: monaco.languages.CompletionItemKind[types[item] || 'Method'],
          range,
        })),
      };
    },
  });
};

const useEditorFeatures = (displayedFile: File) => {
  const dispatch = useDispatch();
  const _editor = useRef<any>(null);
  const _monaco = useRef<any>(null);
  const codeLenseProvider = useRef<any>(null);
  const showCodeLensesTimeout = useRef<NodeJS.Timeout>();

  const error = useCodeError(displayedFile);

  useEffect(() => {
    dispatch(setFileHasError({ hasError: !!error, fileId: displayedFile.id }));
  }, [error]);

  // TODO: fix group edit
  // const { subscribeToMonacoChanges } = useMonacoEditorGroupEdit(displayedFile);

  const getFileActions = () => {
    const actionRegex = /class (.*)\(Action.*\)/;
    const validateActionRegex = /class (.*)\(FormValidationAction.*\)/;
    const validateMethodRegex = /def validate_(.*)\(self, .*\)/;
    let lastValidateActionName = '';
    const fileActions = [];
    displayedFile.newContent.split('\n').forEach((line, index) => {
      const matchAction = line.match(actionRegex);
      if (matchAction) {
        const actionName = matchAction[1];
        fileActions.push({
          name: actionName,
          title: 'Run action',
          line: index + 1,
          command: _editor.current.addCommand(
            -1,
            () => {
              dispatch(
                setTrackerAction({
                  actionName,
                  projectId: displayedFile.projectId,
                  actionType: 'run',
                  action: displayedFile.newContent,
                }),
              );
            },
            '',
          ),
        });
        fileActions.push({
          name: actionName,
          title: 'Validate action',
          line: index + 1,
          command: _editor.current.addCommand(
            -1,
            () => {
              dispatch(
                setTrackerAction({
                  actionName,
                  projectId: displayedFile.projectId,
                  actionType: 'validate',
                  action: displayedFile.newContent,
                }),
              );
            },
            '',
          ),
        });
      }
      const matchValidateAction = line.match(validateActionRegex);
      if (matchValidateAction) {
        lastValidateActionName = matchValidateAction[1];
      }

      const validateMethod = line.match(validateMethodRegex);
      if (validateMethod) {
        const slotName = validateMethod[1];
        fileActions.push({
          name: slotName,
          title: 'Run validation action',
          line: index + 1,
          command: _editor.current.addCommand(
            -1,
            () => {
              dispatch(
                setSlots([
                  { key: 'requested_slot', value: slotName, hidden: true },
                  { key: slotName, value: '' },
                ]),
              );
              dispatch(
                setTrackerAction({
                  actionName: lastValidateActionName,
                  projectId: displayedFile.projectId,
                  actionType: 'run',
                  action: displayedFile.newContent,
                }),
              );
            },
            '',
          ),
        });
      }
    });
    return fileActions;
  };

  const registerCodeLenses = (fileActions: any[]) =>
    _monaco.current.languages.registerCodeLensProvider('python', {
      provideCodeLenses(_model, _token) {
        return {
          lenses: [
            ...fileActions.map((action) => ({
              range: {
                startLineNumber: action.line,
                startColumn: action.line,
                endLineNumber: action.line,
                endColumn: action.line,
              },
              command: {
                id: action.command,
                title: action.title,
              },
            })),
          ],
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          dispose: () => {},
        };
      },
      resolveCodeLens(model, codeLens, _token) {
        return codeLens;
      },
    });

  const showCodeLenses = () => {
    if (!_editor.current || !_monaco.current) {
      return;
    }
    if (codeLenseProvider.current) {
      codeLenseProvider.current.dispose();
    }
    const fileActions = getFileActions();
    codeLenseProvider.current = registerCodeLenses(fileActions);
  };

  const setupAutocomplete = () => {
    if (isAutocompleteSetup) {
      return;
    }
    if (!_monaco.current) {
      return;
    }
    const monaco = _monaco.current;
    const languages = {
      cpp: { language: cpp, types: cppTypes },
      python: { language: py, types: pyTypes },
    };
    Object.entries(languages).forEach(([languageName, { language, types }]) => {
      setupMainLanguageSuggestions(monaco, languageName, language, types);
      const setupSubSuggestions = (
        sublanguage: Record<string, any>,
        prefixWords: string[],
      ) => {
        Object.entries(sublanguage).forEach(([key, value]) => {
          const newPrefixWords = [...prefixWords, key];
          if (value !== true) {
            setupSubLanguageSuggestions(
              monaco,
              languageName,
              types,
              newPrefixWords,
              Object.keys(value),
            );
            setupSubSuggestions(value, newPrefixWords);
          }
        });
      };
      setupSubSuggestions(language, []);
    });
    isAutocompleteSetup = true;
  };

  const editorDidMount = (editor, monaco) => {
    _editor.current = editor;
    _monaco.current = monaco;
    // subscribeToMonacoChanges(editor, monaco);
    showCodeLenses();
    setupAutocomplete();
  };

  useEffect(() => {
    if (!_editor.current || !_monaco.current) {
      return;
    }
    if (error) {
      _monaco.current.editor.setModelMarkers(
        _editor.current.getModel(),
        'errors',
        [
          {
            startLineNumber: error.line,
            startColumn: 0,
            endLineNumber: error.line,
            endColumn: error.column,
            message: error.message,
            severity: _monaco.current.MarkerSeverity.Error,
          },
        ],
      );
    } else {
      _monaco.current.editor.setModelMarkers(
        _editor.current.getModel(),
        'errors',
        [],
      );
    }
  }, [error]);

  useEffect(() => {
    if (showCodeLensesTimeout.current) {
      clearTimeout(showCodeLensesTimeout.current);
    }
    showCodeLensesTimeout.current = setTimeout(showCodeLenses, 250);
    return () => {
      if (codeLenseProvider.current) {
        codeLenseProvider.current.dispose();
      }
    };
  }, [displayedFile.newContent]);

  return [editorDidMount];
};

export default useEditorFeatures;
