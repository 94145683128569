import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import useDispatch from '@hooks/useDispatch';
import { userSelector } from '@selectors';
import { loginGuest, loginUser } from '@slices/userSlices';

let done = false;

const LoginForm = (props: { isGuest?: boolean }) => {
  const { isGuest } = props;
  const dispatch = useDispatch();
  const userState = useSelector(userSelector);

  const { register, handleSubmit } = useForm<{
    username: string;
    password: string;
  }>();

  useEffect(() => {
    if (done) {
      return;
    }
    done = true;
    if (isGuest) {
      dispatch(loginGuest());
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Email address</FormLabel>
            <Input
              borderColor="gray.400"
              {...register('username')}
              name="username"
              placeholder="Email address"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              borderColor="gray.400"
              {...register('password')}
              name="password"
              placeholder="Password"
              type="password"
            />
          </FormControl>
          <Button
            isLoading={userState.isLoading}
            type="submit"
            bg="primary"
            color="white"
            _hover={{ bg: 'primaryLighter' }}
          >
            Login
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default LoginForm;
