import React from 'react';
import { useRouter } from 'next/router';
import { Box, Text } from '@chakra-ui/react';

const TopBanner = () => {
  const { query } = useRouter();

  if (query.text) {
    return (
      <Box
        background="#dc3545"
        height="46px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Text
          fontFamily="Nunito, sans-serif"
          fontWeight="600"
          justifyContent="center"
          size="lg"
          color="#FFF"
        >
          {query.text}
        </Text>
      </Box>
    );
  }
  return null;
};

export default TopBanner;
