import React, { useState, useEffect } from 'react';
import { useBeforeUnload } from 'react-use';
import { useSelector } from 'react-redux';
import {
  Text,
  CloseButton,
  Tab,
  TabList,
  Tabs,
  Button,
  Box,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { useConfirmDelete } from 'chakra-confirm';
import { GoDotFill } from 'react-icons/go';

import {
  displayedFileIdSelector,
  openFilesSelector,
  projectsSelector,
  userFilePositionsSelector,
  anyFileHasChangesSelector,
  displayedFileSelector,
} from '@selectors';
import {
  saveFiles,
  setDisplayedFileId,
  setIsOpen,
  setNewFileContent,
} from '@slices/fileSlices';
import useDispatch from '@hooks/useDispatch';
import FileCloseModal from './FileCloseModal';

export enum ButtonOptions {
  open = 0,
  close = 1,
}

const OpenFilesNavigation = () => {
  const dispatch = useDispatch();
  const openFiles = useSelector(openFilesSelector);
  const displayedFileId = useSelector(displayedFileIdSelector);
  const displayedFile = useSelector(displayedFileSelector);
  const userFilePositions = useSelector(userFilePositionsSelector);
  const projectState = useSelector(projectsSelector);
  const hasChanges = useSelector(anyFileHasChangesSelector);
  const [showCloseFileModal, setShowCloseFileModal] = useState(false);

  const confirmExit = useConfirmDelete({
    title: 'Are you sure you want exit without saving',
    body: 'All data will be lost',
    buttonText: 'Discard and exit',
  });

  const onCloseFileModal = () => {
    setShowCloseFileModal(false);
  };

  const showFile = async (fileId) => {
    if (projectState.showProjectInfo && projectState.isNewProjectInfo) {
      if (!(await confirmExit())) {
        return;
      }
    }
    dispatch(setDisplayedFileId(fileId));
  };

  useBeforeUnload(hasChanges, 'You have unsaved changes, are you sure?');

  const onDiscard = async () => {
    dispatch(
      setNewFileContent({
        fileId: displayedFile.id,
        newContent: displayedFile.content,
      }),
    );
  };

  useEffect(() => {
    setShowCloseFileModal(false);
  }, []);

  const closeFile = async (fileId) => {
    if (displayedFileId === fileId) {
      const newDisplayedFile =
        [...openFiles].reverse().find((file) => file.id !== fileId)?.id || null;
      dispatch(setDisplayedFileId(newDisplayedFile));
    }
    dispatch(setIsOpen({ isOpen: false, fileId }));
  };

  const saveFile = (fileId: string) => {
    dispatch(saveFiles([fileId]));
  };

  if (openFiles.length === 0) {
    return null;
  }

  const currentIndex =
    projectState.showProjectInfo || !displayedFileId
      ? -1
      : openFiles.findIndex((file) => file?.id === displayedFileId);

  return (
    <>
      <Tabs
        backgroundColor={'#181818'}
        key={displayedFileId}
        index={currentIndex}
        bg="#181818"
        border="transparent"
        overflow="hidden"
        maxW="80vw"
        pl="48px"
      >
        <TabList backgroundColor={'#181818'}>
          {openFiles.map((file, index) => (
            <Tab
              key={file ? file.id : index}
              tabIndex={index}
              as={Button}
              backgroundColor={'#181818'}
              px="2"
              maxH="40px"
              borderRadius="0"
              borderRight="2px solid #37373D"
              _first={{ borderLeft: '2px solid #37373D' }}
              _selected={{ color: 'gray', bg: '#37373D', boxShadow: 'none' }}
              _active={{ bg: 'gray' }}
              onMouseDown={(event) => {
                switch (event.button) {
                  case ButtonOptions.open:
                    return showFile(file.id);
                  case ButtonOptions.close:
                    return closeFile(file.id);
                  default:
                    return undefined;
                }
              }}
            >
              <Box mr="2" ml="2">
                {file ? (
                  <Text
                    overflow={'clip'}
                    maxWidth={'100px'}
                    fontSize="sm"
                    color={
                      file.hasError
                        ? 'red'
                        : index === currentIndex
                        ? 'white'
                        : '#7B7B89'
                    }
                  >
                    {file.name}
                  </Text>
                ) : (
                  <Spinner size="sm" />
                )}
              </Box>

              {file?.isChanged ? (
                <>
                  <GoDotFill
                    size="20"
                    color="white"
                    justify-self="center"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      setShowCloseFileModal(true);
                    }}
                  />
                </>
              ) : (
                <CloseButton
                  size="sm"
                  as="div"
                  _hover={{ backgroundColor: '#7B7B89' }}
                  color={index === currentIndex ? 'white' : 'gray'}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    closeFile(file.id);
                  }}
                />
              )}
              <HStack
                position="absolute"
                bottom="-5px"
                left="0px"
                height="12px"
                spacing="2px"
                backgroundColor={'#181818'}
              >
                {Object.values(userFilePositions)
                  .filter(
                    (userFilePosition) => userFilePosition.fileId === file?.id,
                  )
                  .map((userFilePosition) => (
                    <Box
                      height="12px"
                      width="20px"
                      backgroundColor={userFilePosition.color}
                    />
                  ))}
              </HStack>
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <FileCloseModal
        open={showCloseFileModal}
        onClose={onCloseFileModal}
        closeFile={closeFile}
        saveFile={saveFile}
        displayedFileId={displayedFileId}
        onDiscard={onDiscard}
      />
    </>
  );
};

export default OpenFilesNavigation;
