import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import File from '@projectTypes/File';
import { renderHtmlSelector } from '@selectors';
import { debounce } from 'debounce';
import { Box } from '@chakra-ui/react';
import { setTargetFile } from '@slices/projectSlices';

interface WebDevEditorProps {
  displayedFile: File;
}
const changeHtml = debounce((newHtml, setHtml) => {
  setHtml(newHtml);
}, 1000);

const WebDevEditor = (props: WebDevEditorProps) => {
  const { displayedFile } = props;
  const dispatch = useDispatch();
  const { html: renderHtml, id: renderHtmlId } = useSelector(
    renderHtmlSelector,
  );

  const [htmlCode, setHtml] = useState(renderHtml);
  const debounceOnReload = useRef(renderHtmlId);
  const iframeRef = useRef(null);

  useEffect(() => {
    if (debounceOnReload.current === renderHtmlId) {
      changeHtml(renderHtml, setHtml);
    } else {
      setHtml(renderHtml);
    }
    debounceOnReload.current = renderHtmlId;

  }, [renderHtml]);

  useEffect(() => {
    if (displayedFile) {
      setNewRender(displayedFile.name);
    }
  }, [displayedFile]);

  function getNameFromPath(path: string) {
    let fileNameList = path.split('/');
    let fileName = fileNameList[fileNameList.length - 1];

    return fileName;
  }

  function setNewRender(fileName: string) {
    if (fileName.split('.').includes('html')) {
      dispatch(setTargetFile(fileName));
    } else {
      dispatch(setTargetFile(displayedFile.name));
    }
  }

  return !htmlCode ? (
    <Box
      pl={'8px'}
      pt={'8px'}
      textColor={'lightgray'}
      wordBreak="unset"
      textAlign="center"
    >
      <b>
        {' '}
        Please create an HTML file and write some code to enable the live
        preview!{' '}
      </b>
    </Box>
  ) : (
    <iframe
      key={displayedFile.id + displayedFile.fileCounter}
      data-cy="renderIframe"
      title="renderIframe"
      srcDoc={htmlCode}
      id="renderIframe"
      ref={iframeRef}
      style={{position: 'relative', width: '100%', height: '100%'}}
      onLoad={(e) => {
        if (e?.target) {
          const newRenderFileName = getNameFromPath(
            (e.target as any).contentWindow.location.href,
          );
          setNewRender(newRenderFileName);
        }
      }}
    />
  );
};

export default WebDevEditor;
