import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';

interface LogoutProps {
  onClick: () => void;
  isDisabled: boolean;
}

const Logout = (props: LogoutProps) => {
  const { isDisabled, onClick } = props;

  return (
    <IconButton
      bg="itemBackground"
      color="#1e1e1e"
      aria-label="logout"
      icon={<NotAllowedIcon />}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

export default Logout;
