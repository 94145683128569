import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import Confetti from 'react-dom-confetti';

import useDispatch from '@hooks/useDispatch';
import { selectedProjectSelector, userSelector } from '@selectors';
import { setShowProjectShare } from '@slices/projectSlices';
import { publishProject, trainProject } from '@slices/terminalSlices';
import config from '@config';

export const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: '0.11',
  duration: 3000,
  stagger: '0',
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const ProjectShareModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [chatbotUrl, setChatbotUrl] = useState<string | null>(null);
  const { hasCopied, onCopy } = useClipboard(chatbotUrl);
  const selectedProject = useSelector(selectedProjectSelector);
  const user = useSelector(userSelector);

  const closeModal = () => {
    dispatch(setShowProjectShare(null));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await dispatch(
      trainProject({
        project: selectedProject,
        shouldBootChat: false,
        shouldShare: true,
      }),
    );
    setIsLoading(false);
  };

  const publish = async () => {
    if (!selectedProject.runId) {
      return;
    }
    setIsPublishing(true);
    try {
      await dispatch(publishProject({ trainingId: selectedProject.runId }));
      setIsPublishing(false);
    } catch (e) {
      setIsPublishing(false);
      throw e;
    }
  };

  useEffect(() => {
    if (selectedProject && selectedProject.runId) {
      setChatbotUrl(
        `${config.webUrl}/chat?projectId=${selectedProject.id}&runId=${selectedProject.runId}`,
      );
    }
  }, [selectedProject.runId]);

  return (
    <Modal isOpen onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Publish project</ModalHeader>
        <ModalBody justifyContent="center" alignItems="center">
          <Confetti active={!!chatbotUrl} config={confettiConfig as any} />
          {chatbotUrl ? (
            <VStack spacing={8}>
              <Text color="primary">
                Congratulations! You have successfully published chatbot. Use
                this link to share with your friends!
              </Text>
              <Flex mb={2} width="100%">
                <Input
                  value={chatbotUrl}
                  isReadOnly
                  placeholder="Welcome"
                  size="sm"
                  noOfLines={2}
                />
                <Button onClick={onCopy} ml={2}>
                  {hasCopied ? 'Copied' : 'Copy'}
                </Button>
              </Flex>
              {user.role === 'Admin' && (
                <Button onClick={publish}>Publish</Button>
              )}
            </VStack>
          ) : (
            <form onSubmit={onSubmit}>
              <VStack spacing={8}>
                <Text color="warning">
                  This action will train the project and give you a sharable
                  link to share with friends.
                </Text>
                <Button
                  isLoading={isLoading}
                  type="submit"
                  bg="primary"
                  color="white"
                  mb="10px"
                >
                  Publish
                </Button>
              </VStack>
            </form>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectShareModal;
