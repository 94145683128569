import React from 'react';
import { useSelector } from 'react-redux';

import TrackerModal from '@components/Modals/TrackerModal';
import ProjectShareModal from '@components/Modals/ProjectShareModal';
import { projectsSelector, trackerSelector } from '@selectors';

const ModalEditorContainer = () => {
  const trackerState = useSelector(trackerSelector);
  const projectState = useSelector(projectsSelector);

  if (projectState.showProjectShare) {
    return <ProjectShareModal />;
  } else if (trackerState.trackerAction) {
    return <TrackerModal />;
  }

  return null;
};

export default ModalEditorContainer;
