import React from 'react';
import { Skeleton } from '@chakra-ui/react';

const BaseSkeleton = (props: any) => (
  <Skeleton
    startColor="#AAA"
    endColor="primary"
    h="40px"
    w="120px"
    {...props}
  />
);

const ProjectTreeSkeleton = () => {
  return (
    <>
      <BaseSkeleton w="120px" />
      <BaseSkeleton w="100px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="120px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="80px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="100px" style={{ marginLeft: 40 }} />
      <BaseSkeleton w="100px" style={{ marginLeft: 40 }} />
      <BaseSkeleton w="120px" />
      <BaseSkeleton w="120px" />
      <BaseSkeleton w="120px" />
      <BaseSkeleton w="100px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="120px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="80px" style={{ marginLeft: 20 }} />
      <BaseSkeleton w="100px" style={{ marginLeft: 40 }} />
      <BaseSkeleton w="120px" />
    </>
  );
};

export default ProjectTreeSkeleton;
