import React from 'react';
import {
  Button,
  Image,
  ImgProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

const ModalImage = (props: ImgProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { src } = props;

  return (
    <>
      <Image {...props} onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={src} height="100%" width="100%" objectFit="contain" />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalImage;
