import React, { useState } from 'react';
import useDispatch from '@hooks/useDispatch';
import { Input } from '@chakra-ui/react';

import { sendPythonMessage } from '@slices/terminalSlices';

export const TerminalPrompt = () => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(sendPythonMessage(value));
  };

  return (
    <form
      onSubmit={onSubmit}
      style={{ display: 'inline-block', height: '24px', width: '50%' }}
    >
      <Input
        p="0"
        h="24px"
        w="full"
        border="1px solid white"
        outline="0px"
        borderRadius="4px"
        fontFamily="var(--chakra-fonts-mono)"
        fontSize="var(--chakra-fontSizes-sm)"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        color="white"
        autoFocus
      />
    </form>
  );
};
