import React from 'react';
import { useSelector } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import { userFilePositionsSelector } from '@selectors';
import { UserFilePosition } from '@projectTypes/RealTimeEdit';

interface FileUsersProps {
  fileId: string;
}

const FileUsers = (props: FileUsersProps) => {
  const { fileId } = props;
  const userFilePositions = useSelector(userFilePositionsSelector);
  return (
    <HStack>
      {Object.values(userFilePositions)
        .reduce((acc, cur) => {
          if (cur.fileId === fileId) {
            acc.push(cur);
          }
          return acc;
        }, [] as UserFilePosition[])
        .map((userFilePosition) => (
          <Box
            key={userFilePosition.uniqueId}
            backgroundColor={userFilePosition.color}
            height="30px"
            width="30px"
            borderRadius="15px"
            justifyContent="center"
            overflow="hidden"
          >
            <Text
              color="#FFF"
              textAlign="center"
              justifyContent="center"
              width="100%"
              height="100%"
              fontSize="18px"
            >
              {userFilePosition.username}
            </Text>
          </Box>
        ))}
    </HStack>
  );
};

export default FileUsers;
