import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Code,
  VStack,
  Heading,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: Record<string, any>;
};
export const MessageInfoModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, data } = props;

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Message info</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MessageBodyRender data={data} />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type BodyProps = { data: Record<string, any> };
const MessageBodyRender: React.FC<BodyProps> = ({ data }) => {
  if (data.status === 'FAILURE' && data.traceback && data.result.exc_message) {
    return (
      <VStack align="flex-start">
        <Heading size="md">An error occurred</Heading>
        <Heading size="sm" color="red.500">
          {data.result.exc_message[0]}
        </Heading>

        <Code whiteSpace="pre-wrap" color="red.500">
          {data.traceback.split('\n').map((line, index) => (
            <pre
              // eslint-disable-next-line react/no-array-index-key
              key={`${line}-${index}`}
              style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }}
            >
              {line}
            </pre>
          ))}
        </Code>
      </VStack>
    );
  }

  return (
    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};
