import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { userSelector } from '@selectors';
import { logoutUser } from '@slices/userSlices';
import useDispatch from '@hooks/useDispatch';

import Logout from './Logout';
import { color } from 'framer-motion';


const UserContainer = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  if (!user.isLoggedIn) {
    return null;
  }
  return (
    <HStack maxW="250px" pl="5px" pr="5px" >
      <Logout
        isDisabled={false}
        onClick={() => {
          dispatch(logoutUser());
        }}
      />
      <Text fontSize="xs" color={'white'}>{user.username}</Text>
    </HStack>
  );
};

export default UserContainer;
