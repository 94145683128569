import { useEffect } from 'react';
import useDispatch from '@hooks/useDispatch';

import {
  subscribeToChanges,
  unsubscribeFromChanges,
} from '@slices/realTimeEditSlices';
import Project from '@projectTypes/Project';

const useGroupEdit = (selectedProject: Project) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    if (selectedProject.isGroupProject) {
      dispatch(subscribeToChanges());
    } else {
      dispatch(unsubscribeFromChanges());
    }
  }, [selectedProject]);
};

export default useGroupEdit;
