import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  VStack,
  HStack,
  Stack,
  Text,
  Heading,
  Spinner,
  Select,
  Link,
} from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import {
  deviceSelector,
  commitSelector,
  sortedDevicesSelector,
} from '@selectors';
import {
  connectToDevice,
  disconnectFromDevice,
  setIsLoadingVersionsFromDevice,
  setShowDeviceModal,
} from '@slices/deviceSlices';
import { runLogs } from '@/slices/terminalSlices';
import MqttService from '@/services/MqttService';
import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import DeviceSelect from '../DeviceSelect';

interface SelectDeviceModalProps {
  onClose?: () => void;
}

let loadingVersions = false;
let upgrading = false;

const SelectDeviceModal = (props: SelectDeviceModalProps) => {
  const { onClose: onCloseCallback } = props;
  const dispatch = useDispatch();
  const {
    isLoading,
    connectedDevice,
    connectedDeviceVersions,
    libVersions,
    firmwareVersions,
    isLoadingVersionsFromDevice,
  } = useSelector(deviceSelector);
  const sortedDevices = useSelector(sortedDevicesSelector);
  const { showCommitModal } = useSelector(commitSelector);
  const [versionsTimedout, setVersionsTimedout] = useState<boolean>(false);
  const [actionTimeout, setActionTimeout] = useState<boolean>(false);
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(
    connectedDevice != null ? connectedDevice.id : '',
  );
  const [selectedLibVersion, setSelectedLibVersion] = useState<
    string | undefined
  >();
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState<
    string | undefined
  >();

  const latestLibVersion = libVersions ? libVersions[0] : undefined;
  const latestFirmwareVersion = firmwareVersions
    ? firmwareVersions[0]
    : undefined;

  const disconnect = () => {
    if (connectedDevice != null) {
      dispatch(disconnectFromDevice(connectedDevice.id));
    }
  };

  const onClose = () => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    dispatch(setShowDeviceModal(false));
  };

  const versionCheckTimeout = () => {
    if (loadingVersions) {
      dispatch(setIsLoadingVersionsFromDevice(false));
      loadingVersions = false;
      setVersionsTimedout(true);
    }
  };

  const checkUpgradingTimeout = () => {
    if (upgrading) {
      setActionTimeout(true);
      setIsUpgrading(false);
    } else {
      setActionTimeout(false);
    }
    upgrading = false;
  };

  const checkVersions = () => {
    dispatch(setIsLoadingVersionsFromDevice(true));
    setVersionsTimedout(false);
    MqttService.publish(
      `device/${connectedDevice.id}/command`,
      JSON.stringify({ action: 'check_versions' }),
    );
    loadingVersions = true;
    setTimeout(versionCheckTimeout, 3000);
  };

  useEffect(() => {
    if (connectedDevice) {
      setSelectedDeviceId(connectedDevice.id);
      if (
        connectedDeviceVersions?.libVersion == null &&
        connectedDeviceVersions?.firmwareVersion == null &&
        !isLoadingVersionsFromDevice
      ) {
        checkVersions();
      }
    }
  }, [connectedDevice, connectedDeviceVersions]);

  useEffect(() => {
    if (connectedDevice) {
      setSelectedDeviceId(connectedDevice.id);
      if (
        connectedDeviceVersions?.libVersion == null &&
        connectedDeviceVersions?.firmwareVersion == null &&
        !isLoadingVersionsFromDevice
      ) {
        checkVersions();
      }
    }
  }, []);

  const canConnectToDevice =
    selectedDeviceId?.length > 0 && connectedDevice?.id !== selectedDeviceId;

  useEffect(() => {
    if (libVersions.length >= 1 && selectedLibVersion == null) {
      setSelectedLibVersion(libVersions[0]);
    }
    if (firmwareVersions.length >= 1 && selectedFirmwareVersion == null) {
      setSelectedFirmwareVersion(firmwareVersions[0]);
    }
  }, [firmwareVersions, libVersions]);

  useEffect(() => {
    if (
      connectedDeviceVersions?.libVersion != null &&
      selectedLibVersion == null &&
      libVersions.includes(connectedDeviceVersions?.libVersion)
    ) {
      setSelectedLibVersion(connectedDeviceVersions?.libVersion);
    }
    if (
      connectedDeviceVersions?.firmwareVersion != null &&
      selectedFirmwareVersion == null &&
      firmwareVersions.includes(connectedDeviceVersions?.firmwareVersion)
    ) {
      setSelectedFirmwareVersion(connectedDeviceVersions?.firmwareVersion);
    }
    setIsUpgrading(false);
    upgrading = false;
  }, [connectedDeviceVersions]);

  return (
    <Modal isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Select a device to connect to</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody position="relative">
          <VStack alignItems="start">
            {showCommitModal && (
              <Text>
                You need to connect to a device before you can commit.
              </Text>
            )}
            <HStack mr={16} alignItems="center">
              <Text fontSize="">
                {connectedDevice ? 'Connected to' : 'Connect to a device'}:
              </Text>
              <Stack direction="column" spacing={0} alignItems="start">
                <DeviceSelect
                  devices={sortedDevices}
                  onSelect={(v: string) => {
                    if (v != null) {
                      setSelectedDeviceId(v);
                    } else {
                      setSelectedDeviceId('');
                      disconnect();
                    }
                  }}
                  selectedDeviceId={selectedDeviceId}
                />
              </Stack>
            </HStack>
            {connectedDevice && (
              <VStack justify="start" align="start" pt={4}>
                <HStack w="full">
                  <Text>Library version:</Text>
                  {isLoadingVersionsFromDevice ? (
                    <Spinner />
                  ) : (
                    <Text
                      color={
                        connectedDeviceVersions?.libVersion === latestLibVersion
                          ? 'green'
                          : 'orange'
                      }
                    >
                      {connectedDeviceVersions?.libVersion !==
                      latestLibVersion ? (
                        <WarningTwoIcon w="6" h="6" mr="2" />
                      ) : (
                        <CheckIcon w="6" h="6" mr="2" />
                      )}
                      {connectedDeviceVersions?.libVersion || 'Unknown'}
                    </Text>
                  )}
                  {!isLoadingVersionsFromDevice && libVersions.length > 0 && (
                    <Select
                      pl={12}
                      w="auto"
                      onChange={(e) => {
                        setSelectedLibVersion(e.target.value);
                      }}
                      defaultValue={connectedDeviceVersions?.libVersion}
                    >
                      {libVersions.map((v) => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                    </Select>
                  )}
                  {!isLoadingVersionsFromDevice && selectedLibVersion && (
                    <Button
                      isLoading={isUpgrading}
                      onClick={() => {
                        if (
                          selectedLibVersion ===
                          connectedDeviceVersions?.libVersion
                        ) {
                          MqttService.publish(
                            `device/${connectedDevice?.id}/command`,
                            JSON.stringify({
                              action: 'check_lib_integrity',
                              options: { target: selectedLibVersion },
                            }),
                          );
                        } else {
                          MqttService.publish(
                            `device/${connectedDevice?.id}/command`,
                            JSON.stringify({
                              action: 'upgrade_lib',
                              options: { target: selectedLibVersion },
                            }),
                          );
                        }
                        setIsUpgrading(true);
                        upgrading = true;
                        setActionTimeout(false);
                        setTimeout(() => {
                          checkUpgradingTimeout();
                        }, 60000);
                        dispatch(runLogs());
                      }}
                    >
                      {!connectedDeviceVersions?.libVersion ||
                      selectedLibVersion > connectedDeviceVersions?.libVersion
                        ? 'Upgrade'
                        : selectedLibVersion ===
                          connectedDeviceVersions?.libVersion
                        ? 'Check integrity'
                        : 'Downgrade'}
                    </Button>
                  )}
                </HStack>
                <HStack w="full" pt={4}>
                  <Text>Firmware version:</Text>
                  {isLoadingVersionsFromDevice ? (
                    <Spinner />
                  ) : (
                    <Text
                      color={
                        connectedDeviceVersions?.firmwareVersion ===
                        latestFirmwareVersion
                          ? 'green'
                          : 'orange'
                      }
                    >
                      {connectedDeviceVersions?.firmwareVersion !==
                      latestFirmwareVersion ? (
                        <WarningTwoIcon w="6" h="6" mr="2" />
                      ) : (
                        <CheckIcon w="6" h="6" mr="2" />
                      )}
                      {connectedDeviceVersions?.firmwareVersion || 'Unknown'}
                    </Text>
                  )}
                  {!isLoadingVersionsFromDevice && firmwareVersions.length > 0 && (
                    <Select
                      pl={12}
                      w="auto"
                      onChange={(e) => {
                        setSelectedFirmwareVersion(e.target.value);
                      }}
                      defaultValue={connectedDeviceVersions?.firmwareVersion}
                    >
                      {firmwareVersions.map((v) => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                    </Select>
                  )}
                  {!isLoadingVersionsFromDevice &&
                    selectedFirmwareVersion &&
                    selectedFirmwareVersion !==
                      connectedDeviceVersions?.firmwareVersion && (
                      <Button
                        isLoading={isUpgrading}
                        onClick={() => {
                          MqttService.publish(
                            `device/${connectedDevice?.id}/command`,
                            JSON.stringify({
                              action: 'upgrade_firmware',
                              options: { target: selectedFirmwareVersion },
                            }),
                          );
                          setIsUpgrading(true);
                          upgrading = true;
                          setActionTimeout(false);
                          setTimeout(() => {
                            checkUpgradingTimeout();
                          }, 180000);
                          dispatch(runLogs());
                        }}
                      >
                        {!connectedDeviceVersions?.firmwareVersion ||
                        selectedFirmwareVersion >
                          connectedDeviceVersions?.firmwareVersion
                          ? 'Upgrade'
                          : 'Downgrade'}
                      </Button>
                    )}
                </HStack>
                {versionsTimedout &&
                  !(
                    connectedDeviceVersions?.libVersion ||
                    connectedDeviceVersions?.firmwareVersion
                  ) && (
                    <Text pt={4}>
                      Device didn&#39;t respond to version check. It may be
                      offline or running it is too old software and needs to
                      be&nbsp;
                      <Link
                        color="blue.500"
                        href="https://docs.stemi.education/docs/shield/updating"
                        isExternal
                      >
                        manually updated
                      </Link>
                      .
                    </Text>
                  )}
                {actionTimeout && (
                  <Text pt={4}>
                    Device didn&#39;t respond. Check device display for more
                    information.
                  </Text>
                )}
              </VStack>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isLoading}
            disabled={!connectedDevice}
            variant="outline"
            colorScheme="blue"
            onClick={disconnect}
          >
            Disconnect
          </Button>
          <Button
            marginLeft={2}
            isLoading={isLoading}
            disabled={!canConnectToDevice}
            colorScheme="blue"
            onClick={() => {
              dispatch(connectToDevice(selectedDeviceId));
              // dispatch(setShowDeviceModal(false));
            }}
          >
            Connect to device
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectDeviceModal;
