import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { projectsSelector, selectedProjectSelector } from '@selectors';

const useResizeObserver = (query: string) => {
  const interval = useRef(null);
  const projectState = useSelector(projectsSelector);
  const selectedProject = useSelector(selectedProjectSelector);

  const templateSlug = selectedProject
    ? projectState.templates.find((t) => t.id === selectedProject.templateId)
        ?.slug || ''
    : '';

  const resizeObserver = new ResizeObserver((entries) => {
    window.top.postMessage(
      {
        event: 'editor_height',
        height: entries[0].target.clientHeight,
        templateSlug,
      },
      '*',
    );
  });
  useEffect(() => {
    interval.current = setInterval(() => {
      const el = document.querySelector(query);
      if (el) {
        resizeObserver.observe(el);
        clearInterval(interval.current);
      }
    }, 500);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);
};

export default useResizeObserver;
