import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Menu,
  VStack,
  MenuButton,
} from '@chakra-ui/react';
import { projectToInfoObject } from './ProjectInfoEditor/util/handleInfo';
import { selectedProjectSelector } from '@/selectors';

interface FileCloseModalProps {
  open: boolean;
  onClose: () => void;
  closeFile: (async) => void;
  saveFile: (async) => void;
  onDiscard: () => void;
  displayedFileId: string;
}

const FileCloseModal = (props: FileCloseModalProps) => {
  const {
    open,
    onClose,
    closeFile,
    saveFile,
    onDiscard,
    displayedFileId,
  } = props;

  const project = useSelector(selectedProjectSelector);

  return (
    <>
      <Modal onClose={onClose} isOpen={open} size="md">
        <ModalOverlay />
        <ModalContent width="20%" height="35%" backgroundColor="#3D3D3D">
          <Box
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
            display="flex"
          >
            <ModalBody>
              <Box width="inherit">
                <Text
                  fontSize="20px"
                  textColor="white"
                  textAlign="center"
                  noOfLines={3}
                  wordBreak="break-word"
                  marginBottom="10px"
                >
                  <b>Do you want to save the changes you made?</b>{' '}
                </Text>
                <Text
                  fontSize="15px"
                  textColor="white"
                  textAlign="center"
                  noOfLines={3}
                  wordBreak="break-word"
                  marginBottom="20px"
                >
                  Your changes will be lost if you don't save them.
                </Text>
              </Box>
              <Box alignItems="center">
                <VStack>
                  <Menu>
                    <MenuButton
                      background="#007acc"
                      color="#fff"
                      padding="10px 20px"
                      border="none"
                      _hover={{ background: '#2b2d2e' }}
                      borderRadius="5px"
                      width="100%"
                      height="30px"
                      marginBottom="15px"
                      onClick={() => {
                        saveFile(displayedFileId);
                        closeFile(displayedFileId);
                        onClose();
                      }}
                    >
                      Save
                    </MenuButton>
                    <MenuButton
                      background="#b30f04"
                      color="#fff"
                      padding="10px 20px"
                      border="none"
                      border-radius="5px"
                      borderRadius="5px"
                      width="100%"
                      height="30px"
                      onClick={() => {
                        closeFile(displayedFileId);
                        onDiscard();
                        onClose();
                      }}
                    >
                      Discard
                    </MenuButton>
                    <MenuButton
                      backgroundColor={'#181818'}
                      _hover={{ background: '#2b2d2e' }}
                      justifyContent="center"
                      display="flex"
                      textColor={'white'}
                      borderRadius="5px"
                      width="100%"
                      height="30px"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </MenuButton>
                  </Menu>
                </VStack>
              </Box>
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileCloseModal;
