import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  VStack,
  Stack,
  Box,
  Heading,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import {
  selectProject,
  selectProjectFromTemplate,
} from '@slices/projectSlices';
import { projectsSelector } from '@selectors';
import ProjectSummary from './ProjectSummary';
import TemplateSummary from './TemplateSummary';

const SHOW_PROJECT_COUNT = 5;

interface SelectProjectModalProps {
  onClose?: () => void;
}

const SelectProjectModal = (props: SelectProjectModalProps) => {
  const { onClose: onCloseCallback } = props;
  const dispatch = useDispatch();
  const {
    projects,
    templates,
    isLoading,
    lastOpenedProject,
    hasMultiYear,
  } = useSelector(projectsSelector);

  const [isOpen, setIsOpen] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [userSelection, setUserSelection] = useState<{
    id: string;
    isTemplate: boolean;
  }>({ id: '', isTemplate: false });

  const sortedProjects = [...projects].sort((a, b) => {
    return (
      // type punning, yay :D
      Date.parse((b.lastFileUpdatedAt as unknown) as string).valueOf() -
      Date.parse((a.lastFileUpdatedAt as unknown) as string).valueOf()
    );
  });

  const onClose = () => {
    setIsOpen(false);
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  const openProject = (id: string) => {
    dispatch(selectProject(id));
    setIsOpen(false);
    onClose();
  };

  const openTemplate = (id: string) => {
    const templateSlug = templates.find((t) => t.id === id).slug;
    dispatch(selectProjectFromTemplate(templateSlug));
    setIsOpen(false);
    onClose();
  };

  const onClickProjectOrTemplate = (id: string, isTemplate = false) => {
    if (userSelection.id === id) {
      if (userSelection.isTemplate) {
        openTemplate(id);
      } else {
        openProject(id);
      }
    }
    setUserSelection({ id, isTemplate });
  };

  const sandboxTemplates = templates.filter((t) => t.sandbox);
  const filteredProjects = sortedProjects.filter((p) => {
    if (searchValue) {
      return p.name.toLowerCase().includes(searchValue.toLowerCase());
    }

    return true;
  });

  useEffect(() => {
    if (
      userSelection.isTemplate ||
      !userSelection.id ||
      filteredProjects.find((p) => p.id === userSelection.id)
    ) {
      if (
        filteredProjects.length === 1 &&
        (!userSelection.id || userSelection.id !== filteredProjects[0].id)
      ) {
        setUserSelection({ id: filteredProjects[0].id, isTemplate: false });
      }

      return;
    }

    setUserSelection({ id: '', isTemplate: false });
  }, [filteredProjects, userSelection]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Select a project to open</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody position="relative">
          <SimpleGrid columns={2} columnGap="8">
            <VStack alignItems="start" data-cy="projects-modal">
              <Heading size="sm">Recently edited projects:</Heading>
              <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                  e.preventDefault();
                  if (userSelection.id) {
                    if (userSelection.isTemplate) {
                      openTemplate(userSelection.id);
                    } else {
                      openProject(userSelection.id);
                    }
                  }
                }}
              >
                <Input
                  w="full"
                  data-cy="projects-modal:search-input"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </form>

              {filteredProjects.length ? (
                <Stack
                  direction="column"
                  alignItems="start"
                  w="full"
                  data-cy="projects-modal:recent-projects"
                >
                  {filteredProjects.slice(0, SHOW_PROJECT_COUNT).map((p) => (
                    <ProjectSummary
                      project={p}
                      key={p.id}
                      hasMultiYear={hasMultiYear}
                      selected={p.id === userSelection.id}
                      onClick={() => onClickProjectOrTemplate(p.id)}
                      onDoubleClick={() => openProject(p.id)}
                    />
                  ))}

                  {filteredProjects.length > SHOW_PROJECT_COUNT && (
                    <Heading size="sm">
                      {filteredProjects.length - SHOW_PROJECT_COUNT} more ...
                    </Heading>
                  )}
                </Stack>
              ) : (
                <Heading size="sm">No projects...</Heading>
              )}
            </VStack>
            <VStack alignItems="start">
              <Heading size="sm">Create from template:</Heading>
              <VStack alignItems="start">
                {sandboxTemplates.map((t) => (
                  <TemplateSummary
                    template={t}
                    key={t.id}
                    selected={
                      t.id === userSelection.id && userSelection.isTemplate
                    }
                    onClick={() => onClickProjectOrTemplate(t.id, true)}
                    onDoubleClick={() => openTemplate(t.id)}
                  />
                ))}
              </VStack>
            </VStack>
          </SimpleGrid>
          <Box
            // to disable clicking projects
            display={isLoading ? 'flex' : 'none'}
            // backgroundColor="#00000055"
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
          />
        </ModalBody>

        <ModalFooter>
          <Button
            data-cy="projects-modal:action-button"
            isLoading={isLoading}
            colorScheme="blue"
            onClick={() => {
              if (userSelection.id !== '') {
                if (userSelection.isTemplate) {
                  openTemplate(userSelection.id);
                } else {
                  openProject(userSelection.id);
                }
              } else if (lastOpenedProject != null) {
                openProject(lastOpenedProject);
              } else {
                onClose();
              }
            }}
          >
            {userSelection.id !== ''
              ? userSelection.isTemplate
                ? 'Create from template'
                : 'Open project'
              : lastOpenedProject != null
              ? 'Continue with last project'
              : 'Close'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectProjectModal;
