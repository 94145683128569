import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Select, { components, OptionProps } from 'react-select';
import { Box, Flex } from '@chakra-ui/react';
import classNames from 'classnames';

import Device from '@projectTypes/Device';
import { userSelector } from '@selectors';
import { useReactSelectStyles } from './ProjectContainer/useReactSelectStyles';

interface DeviceSelectProps {
  devices: Device[];
  selectedDeviceId: string | undefined;
  onSelect: (_deviceId: string) => void;
}

const Option = (props: OptionProps<Device>) => {
  const { data, selectOption } = props;
  const { id: userId } = useSelector(userSelector);

  const statusMsg =
    data.lastUsedById != null && data.lastUsedById.length > 0
      ? data.lastUsedById === userId
        ? 'Connected'
        : 'In use'
      : 'Available';

  return (
    <Flex
      onClick={() => {
        selectOption(data);
      }}
      direction="column"
      _hover={{
        bg: 'var(--chakra-colors-primary)',
        cursor: 'pointer',
        color: 'white',
      }}
      sx={{
        '&.available .status': {
          color: 'green',
        },
        '&.connected .status': {
          color: 'var(--chakra-colors-primary)',
        },
        '&.connected:hover .status': {
          color: 'white',
        },
        '&.inUse .status': {
          color: 'orange',
        },
        '& .status': {
          fontSize: '12px',
        },
      }}
      paddingX={4}
      paddingY={2}
      className={classNames({
        available: data.lastUsedById == null,
        connected:
          data.lastUsedById != null &&
          data.lastUsedById.length > 0 &&
          data.lastUsedById === userId,
        inUse: data.lastUsedById != null && data.lastUsedById.length > 0,
        noMac: data.mac == null,
      })}
    >
      <components.Option {...props} />
      <span className="status">{statusMsg}</span>
      {data.online && <span className="status">Online</span>}
    </Flex>
  );
};

const DeviceSelect = (props: DeviceSelectProps) => {
  const { devices, selectedDeviceId, onSelect } = props;
  const options = (() => {
    const groups = devices.reduce((groups, device) => {
      const key = `${device.schoolId}-${device.schoolName}`;
      if (!groups[key]) {
        groups[key] = [];
      }

      groups[key].push(device);
      return groups;
    }, {} as Record<string, Device[]>);

    if (Object.entries(groups).length > 1) {
      return Object.values(groups).map((devices) => {
        return {
          label: devices[0].schoolName,
          options: devices,
        };
      });
    } else {
      return groups[Object.keys(groups)[0]];
    }
  })();

  const selectedDevice = devices.find((opt) => opt.id === selectedDeviceId);

  // TODO: USE chakra-form for this
  const themed = useReactSelectStyles();
  const styles = useMemo(
    () => ({
      ...themed,
      styles: {
        ...themed.styles,
        control: (base, props) => ({
          ...base,
          ...themed.styles?.control(base, props),
          height: 32,
          minHeight: 32,
        }),
        container: (base, props) => ({
          ...base,
          ...themed.styles?.container(base, props),
          width: '260px',
        }),
        input: (base) => ({ ...base, margin: 0 }),
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (base) => ({ ...base, height: 32 }),
        option: () => ({}),
      },
    }),
    [themed],
  );

  return (
    <Box h="32px">
      <Select<Device, false>
        {...styles}
        components={{ Option }}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={selectedDevice}
        onChange={(opt) => {
          if (opt != null) {
            onSelect(opt.id);
          } else {
            onSelect(null);
          }
        }}
        isClearable
      />
    </Box>
  );
};

export default DeviceSelect;
