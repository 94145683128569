import { useSelector } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';

import { selectedProjectSelector } from '@selectors';
import { saveProjectFiles } from '@slices/fileSlices';

import useDispatch from './useDispatch';

export default function useOnEditorExit() {
  // const store = useSelector(stateSelector);
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectedProjectSelector);

  useBeforeunload(async () => {
    if (selectedProject?.isChanged) {
      if (selectedProject?.isGroupProject) {
        await dispatch(saveProjectFiles(selectedProject.id));
      }
    }
  });
}
