import filbert from 'filbert';
import { FileContentError } from '@projectTypes/Error';

const getPythonErrors = (content: string): FileContentError | null => {
  try {
    let finalContent = content.replaceAll('f"', '"');
    finalContent = finalContent.replaceAll("f'", "'");
    let startTime = performance.now();
    filbert.parse(finalContent);
    let endTime = performance.now();
    console.log(endTime - startTime);
    
    return null;
  } catch (e) {
    const containsTry = content.split('\n')[e.loc.line - 1]?.includes('try');
    if (!containsTry) {
      return {
        message: e.message,
        name: e.name,
        reason: e.reason,
        column: e.loc.column,
        line: e.loc.line,
        position: 0,
      };
    }
  }
};

export default getPythonErrors;
