import React from 'react';
import { Button } from '@chakra-ui/react';

export interface ItemButtonProps {
  selected?: boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

export const ItemButton: React.FC<ItemButtonProps> = (props) => {
  const { selected, onClick, onDoubleClick, children } = props;

  return (
    <Button
      w="full"
      flexDir="column"
      alignItems="flex-start"
      height="fit-content"
      variant={selected ? 'solid' : 'outline'}
      colorScheme="blue"
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      py="2"
    >
      {children}
    </Button>
  );
};
