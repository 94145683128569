import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Modal,
} from '@chakra-ui/react';
import useDispatch from '@hooks/useDispatch';
import { compileProject, setShouldCompileModal } from '@slices/terminalSlices';
import { selectedProjectSelector } from '@selectors';
import { UploadType } from '@projectTypes/Terminal';
import { FaMobileAlt } from 'react-icons/fa';

export interface CompileTypeModalProps {
  data: string;
}

const CompileTypeModal = () => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectedProjectSelector);

  const onClose = () => {
    dispatch(setShouldCompileModal(false));
  };

  // const compileAndUploadUsb = () => {
  //   dispatch(
  //     compileProject({
  //       project: selectedProject,
  //       ota: false,
  //       uploadType: UploadType.USB,
  //       hardReset: false,
  //     }),
  //   );
  //   onClose();
  // };

  const compileAndUploadMobile = () => {
    dispatch(
      compileProject({
        project: selectedProject,
        ota: true,
        uploadType: UploadType.Mobile,
        hardReset: false,
      }),
    );
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select upload type</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          justifyContent="space-between"
          display="flex"
          paddingBottom="30px"
        >
          {/* <Button
            colorScheme="blue"
            onClick={compileAndUploadUsb}
            leftIcon={<FaPlug />}
          >
            USB upload
          </Button> */}
          <Button
            colorScheme="orange"
            onClick={compileAndUploadMobile}
            leftIcon={<FaMobileAlt />}
          >
            Mobile upload
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CompileTypeModal;
