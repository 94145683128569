import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { Box, Button, Text } from '@chakra-ui/react';

import {
  XMLProjectFileSelector,
  displayedFileSelector,
  editorSelector,
  inoProjectFileSelector,
  projectFilesSelector,
  projectsSelector,
  selectedProjectSelector,
  userSelector,
} from '@selectors';
import { setNewFileContent } from '@slices/fileSlices';
import { sendFileData } from '@slices/realTimeEditSlices';
import useDispatch from '@hooks/useDispatch';
import useShortcuts from '@hooks/useShortcuts';

import { setIsProjectChanged } from '@slices/projectSlices';
import CodeEditor from './CodeEditor';
import useGroupEdit from './hooks/useGroupEdit';
import {
  getSpecificFileEditor,
  isSourceCodeVisible,
} from './CodeEditor/helpers/specificFileEditors';

const ProjectInfoEditor = dynamic(import('./ProjectInfoEditor'), {
  ssr: false,
});

const EditorField = () => {
  const dispatch = useDispatch();
  const displayedFile = useSelector(displayedFileSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const projectFiles = useSelector(projectFilesSelector);
  const projectState = useSelector(projectsSelector);
  const editorState = useSelector(editorSelector);
  const mainXml = useSelector(XMLProjectFileSelector);
  const mainIno = useSelector(inoProjectFileSelector);
  const [useSpecificEditor, setUseSpecificEditor] = useState(true);
  const [showSwitchButton, setUseSwitchButton] = useState(true);
  const user = useSelector(userSelector);

  const specificFileEditor = displayedFile
    ? getSpecificFileEditor(displayedFile)
    : null;

  useShortcuts(displayedFile?.id, selectedProject);
  const hasSpecificFileEditor = !!specificFileEditor;

  useEffect(() => {
    setUseSpecificEditor(
      (mainXml) ? true :
      specificFileEditor?.useCodeEditorPrimarily ? false : true,
    );

    // TODO tidy up code
    setUseSwitchButton(
      (useSpecificEditor && specificFileEditor?.specialName === 'Scratch') ||
        specificFileEditor?.specialName === 'ImageViewer' ||
        selectedProject?.type === 'RASA' ||
        (!!!mainXml && mainIno && user.role !== 'Admin')
        ? false
        : true,
    );
  }, [displayedFile?.id]);

  const onChange = (value: string, data?: { blob: any }) => {
    if (!displayedFile) {
      return;
    }
    if (!data && value === displayedFile.newContent) {
      return;
    }
    let selectedFileId = displayedFile.id;
    if (
      (specificFileEditor as any)?.saveToProjectFileName &&
      useSpecificEditor
    ) {
      const file = projectFiles.find(
        (f) => f.name === (specificFileEditor as any).saveToProjectFileName,
      );
      if (file) {
        selectedFileId = file.id;
      }
    }
    dispatch(
      setNewFileContent({
        fileId: selectedFileId,
        newContent: value,
        blob: data?.blob,
      }),
    );
    dispatch(setIsProjectChanged(true));
    // TODO check if group project
    if (hasSpecificFileEditor) {
      dispatch(sendFileData());
    }
  };

  useGroupEdit(selectedProject);

  if (projectState.showProjectInfo && selectedProject) {
    return <ProjectInfoEditor project={selectedProject} />;
  }

  if (!displayedFile) {
    return (
      <Text color="white" p="10px">
        Select file
      </Text>
    );
  }

  if (useSpecificEditor && specificFileEditor) {
    const FileEditor = specificFileEditor.component;
    return (
      <Box h="full">
        {isSourceCodeVisible(displayedFile) && showSwitchButton && (
          <Button
            zIndex={100}
            colorScheme="blue"
            position="absolute"
            mt={editorState.isSmall ? 10 : 2}
            mr={2}
            right={4}
            onClick={() => {
              setUseSpecificEditor(false);
            }}
          >
            Source code
          </Button>
        )}
        <FileEditor
          {...(specificFileEditor.rerenderOnChange
            ? { key: displayedFile.fileCounter }
            : {})}
          displayedFile={displayedFile}
          onChange={onChange}
        />
      </Box>
    );
  }

  return (
    <Box>
      {specificFileEditor && showSwitchButton && (
        <Button
          zIndex={100}
          colorScheme="blue"
          position="absolute"
          mt={editorState.isSmall ? 10 : 2}
          mr={editorState.isSmall ? 0 : 2}
          right={4}
          onClick={() => {
            setUseSpecificEditor(true);
          }}
        >
          {specificFileEditor.changeEditorButtonText || 'Use code editor'}
        </Button>
      )}
      <Box flex={1}>
        <CodeEditor displayedFile={displayedFile} onChange={onChange} />
      </Box>
    </Box>
  );
};

export default EditorField;
