import React from 'react';
import { useSelector } from 'react-redux';

import config from '@config';
import File from '@projectTypes/File';
import useWindowSize from '@hooks/useWindowSize';
import {
  filesSelector,
  projectsSelector,
  fontSizeSelector,
  commitSelector,
  selectedProjectSelector
} from '@selectors';

import CodeEditor from './CodeEditor';
import CodeDiffEditor from './CodeDiffEditor';

interface CodeEditorContainerProps {
  displayedFile: File;
  onChange: (value: any) => void;
}

const CodeEditorContainer = (props: CodeEditorContainerProps) => {
  const { displayedFile, onChange } = props;
  const projectState = useSelector(projectsSelector);
  const fileState = useSelector(filesSelector);
  const commitState = useSelector(commitSelector);
  const selectedProject = useSelector(selectedProjectSelector);

  const language = Object.entries(config.editorFileTypes).reduce(
    (acc, [fileType, fileLanguage]) => {
      if (displayedFile.contentType.includes(fileType)) {
        return fileLanguage;
      }
      return acc;
    },
    'python',
  );

  const { width } = useWindowSize();
  const fontSize = useSelector(fontSizeSelector);
  
  if (
    projectState.showProjectDiff ||
    fileState.showFileDiff ||
    commitState.showDiff
  ) {
    return (
      <CodeDiffEditor
        displayedFile={displayedFile}
        language={language}
        width={width}
        fontSize={fontSize}
        onChange={onChange}
      />
    );
  }

  return (
    <CodeEditor
      displayedFile={displayedFile}
      language={language}
      width={width}
      onChange={onChange}
      fontSize={fontSize}
    />
  );
};

export default CodeEditorContainer;
