import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import File from '@projectTypes/File';
import { commitDiffLeft, commitDiffRight } from '@selectors';
import { Flex, Text } from '@chakra-ui/react';

const MonacoDiffEditor: React.ComponentType<any> = dynamic(
  import(`@monaco-editor/react`).then((mod) => mod.DiffEditor) as any,
  { ssr: false },
);

interface CodeEditorProps {
  displayedFile: File;
  language: string;
  width: number;
  onChange: (value: any) => void;
  fontSize: number;
}

const CodeEditor = (props: CodeEditorProps) => {
  const { displayedFile, language, width, fontSize, onChange } = props;
  const { info: leftInfo, content: leftContent } = useSelector(commitDiffLeft);
  const { info: rightInfo, content: rightContent, isWorkingDraft } = useSelector(
    commitDiffRight,
  );
  const [minWidth, setMinWidth] = useState<number>(100);

  useEffect(() => {
    setMinWidth(99.99999);
    setTimeout(() => setMinWidth(100), 200);
  }, []);

  return (
    <>
      <Flex dir="row">
        <Text
          flexBasis="50%"
          fontFamily='"Droid Sans Mono", "monospace", monospace, "Droid Sans Fallback"'
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.5}px`}
          marginLeft={`${fontSize * 2}px`}
          color="#d4d4d4"
        >
          {leftInfo}
        </Text>
        <Text
          fontFamily='"Droid Sans Mono", "monospace", monospace, "Droid Sans Fallback"'
          flexGrow={1}
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.5}px`}
          color="#d4d4d4"
        >
          {rightInfo}
        </Text>
      </Flex>
      <MonacoDiffEditor
        key={width + displayedFile.fileCounter}
        width={`${minWidth}%`}
        height="100vh"
        language={language}
        theme="vs-dark"
        modified={rightContent}
        onChange={(newContent) => {
          if (isWorkingDraft) {
            onChange(newContent);
          }
        }}
        original={leftContent}
        options={{
          renderSideBySide: true,
          lineHeight: fontSize * 1.5,
          fontSize,
          scrollbar: {
            vertical: 'hidden',
          },
          wordWrap: 'on',
          minimap: {
            enabled: false,
          },
          renderOverviewRuler: false,
          readOnly: !isWorkingDraft,
        }}
      />
    </>
  );
};

export default CodeEditor;
