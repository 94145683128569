import React, { useState } from 'react';
import {
  Avatar,
  Image,
  Box,
  Flex,
  Text,
  Link,
  useDisclosure,
  Button,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import useDispatch from '@hooks/useDispatch';

import { Message as IMessage, MessageDirection } from '@projectTypes/Terminal';
import { sendChatMessage } from '@/slices/terminalSlices';
import { MessageInfoModal } from './MessageInfoModal';

interface MessageProps {
  message: IMessage;
  isChat?: boolean;
  isLastMessage?: boolean;
}

const ALLOWED_MESSAGE_TAGS = [
  'b',
  'i',
  'u',
  'a',
  'ul',
  'ol',
  'li',
  'br',
  'hr',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];
const FormatMessage: React.FC<{ string: string }> = ({ string }) => {
  const content = string.replace(/\n/g, '<br>');
  const tmp = sanitizeHtml(content, {
    allowedTags: ALLOWED_MESSAGE_TAGS,
  });

  return (
    <span
      className="message-class"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: tmp }}
    />
  );
};

const bubbleStyle = (isOutbound, error = undefined) => {
  return {
    bg: isOutbound ? 'primary' : 'secondary',
    textAlign: isOutbound ? 'right' : 'left',
    color: error ? '#fee' : 'white',
    display: 'inline-block',
    px: 3,
    py: 1,
    borderRadius: '12px',
  } as const;
};

// TODO: Fixme me
const ACTION_ERROR_TEXT = 'Error occurred running action';

const Message = (props: MessageProps) => {
  const { message, isLastMessage, isChat } = props;
  const { content, image, error, link, buttons } = message;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const date = dayjs(message.date).format('HH:mm | MMMM D');
  const isOutbound = message.direction === MessageDirection.Outbound;
  const isActionError = content === ACTION_ERROR_TEXT;

  const dispatch = useDispatch();
  const [buttonsClicked, setButtonsClicked] = useState<string>();
  const onButtonClick = (button: typeof buttons[number]) => () => {
    setButtonsClicked(button.payload);
    dispatch(
      sendChatMessage({
        message: `Button: ${button.title}`,
        payload: button.payload,
      }),
    );
  };

  return (
    <>
      <Flex flexDirection={isOutbound ? 'row-reverse' : 'row'}>
        <Avatar size="sm" src="/images/avatar.png" />
        <Box display="inline-block" textAlign={isOutbound ? 'right' : 'left'}>
          {(content || error) && (
            <Text
              {...bubbleStyle(isOutbound, error)}
              {...(!isChat ? { whiteSpace: 'pre' } : {})}
            >
              {content ? <FormatMessage string={content} /> : error}
            </Text>
          )}
          {link && (
            <Link
              {...bubbleStyle(isOutbound)}
              textDecor="underline"
              href={link}
              isExternal
            >
              {link}
            </Link>
          )}
          {image && (
            <Image src={image} alt="image" borderRadius="12px" maxW="60%" />
          )}
          {buttons && (
            <VStack my="2" alignItems="flex-start">
              {buttons.map((button) => (
                <Button
                  key={button.title}
                  variant={
                    button.payload === buttonsClicked ? undefined : 'outline'
                  }
                  backgroundColor={
                    button.payload === buttonsClicked ? 'secondary' : undefined
                  }
                  colorScheme="orange"
                  borderColor="secondary"
                  color={
                    button.payload === buttonsClicked ? undefined : 'secondary'
                  }
                  cursor={isLastMessage ? 'pointer' : 'default'}
                  onClick={isLastMessage ? onButtonClick(button) : undefined}
                >
                  {button.title}
                </Button>
              ))}
            </VStack>
          )}
          <Text
            textAlign={isOutbound ? 'right' : 'left'}
            color="gray.500"
            fontSize="xs"
          >
            {date}
            {message.debug && !isActionError && (
              <>
                {' | '}
                <Box
                  as="span"
                  _hover={{ textDecor: 'underline', cursor: 'pointer' }}
                  onClick={onOpen}
                >
                  Show {isActionError ? 'error' : 'debug'}
                </Box>
              </>
            )}
          </Text>
          {isActionError && (
            <Button colorScheme="red" size="sm" onClick={onOpen}>
              Show error
            </Button>
          )}
        </Box>
      </Flex>

      {message.debug && (
        <MessageInfoModal
          data={message.debug}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Message;
