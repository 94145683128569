import React, { ReactNode } from 'react';
import {
  PythonOriginal,
  YamlPlain,
  ArduinoOriginal,
  XmlOriginal,
  CPlain,
  CplusplusPlain,
} from 'devicons-react';
import { AiFillFileText } from 'react-icons/ai';
import { BiFileBlank } from 'react-icons/bi';
import { FcPicture } from 'react-icons/fc';
import { PiBracketsCurlyBold } from 'react-icons/pi';
import { SiJavascript, SiTypescript, SiCss3, SiHtml5 } from 'react-icons/si';

interface FileIconSelectorProps {
  ext: string;
}
const FileIconSelector = (props: FileIconSelectorProps) => {
  const { ext } = props;

  const cache = new Map<string, ReactNode>();

  cache.set('js', <SiJavascript color="#fbcb38" />);
  cache.set('jsx', <SiJavascript color="#fbcb38" />);
  cache.set('ts', <SiTypescript color="#378baa" />);
  cache.set('tsx', <SiTypescript color="#378baa" />);
  cache.set('css', <SiCss3 color="purple" />);
  cache.set('json', <PiBracketsCurlyBold color="yellow" />);
  cache.set('html', <SiHtml5 color="#e04e2c" />);
  cache.set('png', <FcPicture />);
  cache.set('jpg', <FcPicture />);
  cache.set('ico', <FcPicture />);
  cache.set('txt', <AiFillFileText color="white" />);
  cache.set('py', <PythonOriginal />);
  cache.set('yml', <YamlPlain />);
  cache.set('yaml', <YamlPlain />);
  cache.set('ino', <ArduinoOriginal />);
  cache.set('xml', <XmlOriginal />);
  cache.set('c', <CPlain />);
  cache.set('cpp', <CplusplusPlain color="white" />);
  cache.set('h', <BiFileBlank />);

  return cache.get(ext);
};

export default FileIconSelector;
