import React from 'react';
import { Box, Text, Code, Heading } from '@chakra-ui/react';

import config from '@config';
import { Chart } from '@projectTypes/Terminal';
import ModalImage from '@components/ModalImage';

interface TerminalContentProps {
  children: string;
  charts?: Chart[];
  input?: string;
}

export const TerminalContent = (props: TerminalContentProps) => {
  const { charts, children, input } = props;
  if (children === null) {
    return null;
  }

  const text: string = children.toString();

  if (text.startsWith(config.stemiGraphKeywoard)) {
    const chartUrl = text.split(': ')[1];
    const chart = charts?.find((c) => c.url.includes(chartUrl)) || {
      name: 'Chart',
      url: chartUrl,
    };
    return (
      <Box marginBottom="10px">
        <Heading color="white">{chart.name}</Heading>
        <ModalImage
          src={chart.url}
          maxHeight="300px"
          objectFit="contain"
          objectPosition="left"
          alt={chart.name}
        />
      </Box>
    );
  }

  return (
    <Code color="white" whiteSpace="pre-wrap" bg="transparent">
      {text ? text : ' '}
      {input && (
        <Text display="inline" fontStyle="italic">
          {input}
        </Text>
      )}
    </Code>
  );
};
