import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import Editor from '@components/Editor';
import Terminal from '@components/Terminal';
import Chatbot from '@components/Chatbot';
import LeftSidebar from '@components/LeftSidebar';
import RightSidebar from '@components/RightSidebar';

export const IDE: React.FC = () => {
  return (
    <>
      <Flex h="100vh" bg="background">
        <LeftSidebar />
        <Box w="100%">
          <Editor />
        </Box>
        <RightSidebar />
      </Flex>
      <Terminal />
      <Chatbot />
    </>
  );
};
