import React from 'react';
import { Text } from '@chakra-ui/react';

import { Template } from '@projectTypes/Project';
import { ItemButton, ItemButtonProps } from './ItemButton';

interface TemplateSummaryProps extends ItemButtonProps {
  template: Template;
}

const TemplateSummary: React.FC<TemplateSummaryProps> = (props) => {
  const { template } = props;

  return (
    <ItemButton {...props}>
      <Text fontSize="lg" textAlign="left" whiteSpace="initial">
        {template.name}
      </Text>
    </ItemButton>
  );
};

export default TemplateSummary;
