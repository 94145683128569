import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Code,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import {
  terminalMessageSelector,
  terminalSelector,
  espDeviceSelector,
} from '@selectors';
import { MessageDirection } from '@projectTypes/Terminal';
import { TerminalPrompt } from './TerminalPrompt';
import { TerminalContent } from './TerminalContent';
import { TestResultsModal } from './TestResultsModal';

const MAX_TERMINAL_MESSAGES = 50;

const Terminal = () => {
  const boxRef = useRef(null);
  const terminalState = useSelector(terminalSelector);
  const terminalMessages = useSelector(terminalMessageSelector);
  const espDevice = useSelector(espDeviceSelector);

  const modalProps = useDisclosure();

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView(false);
    }
  }, [terminalMessages.length]);

  return (
    <Box overflowY="auto">
      <Flex
        ref={boxRef}
        bg="black"
        h="100%"
        minH="210px"
        paddingBottom="20px"
        flexDirection="column-reverse"
        justifyContent="start"
      >
        <Box flex="1 1 auto" minH="20px" />

        {terminalState.testResults?.length > 0 && (
          <>
            <Button onClick={modalProps.onOpen}>Show details</Button>
            <TestResultsModal modalProps={modalProps} />
          </>
        )}
        {terminalState.isWaitingResponse && <Spinner size="md" color="white" />}

        {terminalMessages
          .slice(
            Math.max(0, terminalMessages.length - MAX_TERMINAL_MESSAGES),
            terminalMessages.length,
          )
          .reduce((acc, cur) => {
            if (!espDevice) {
              acc.push(cur);
              return acc;
            }
            if (acc.length === 0) {
              acc.push(cur);
              return acc;
            }
            if (
              typeof cur.content === 'string' &&
              cur.direction === MessageDirection.Inbound
            ) {
              if (
                typeof acc[acc.length - 1].content === 'string' &&
                acc[acc.length - 1].content.at(-1) !== '\n' &&
                acc[acc.length - 1].direction === MessageDirection.Inbound
              ) {
                acc[acc.length - 1] = {
                  ...acc[acc.length - 1],
                  content: acc[acc.length - 1].content + cur.content,
                };
              } else {
                acc.push(cur);
              }
            } else {
              acc.push(cur);
            }
            return acc;
          }, [])
          .reverse()
          .map((message) => {
            if (message.style === 'big') {
              return (
                <Heading
                  mb="6"
                  key={message.date.toString()}
                  size="md"
                  color="white"
                >
                  {message.content}
                </Heading>
              );
            }

            if (message.direction === MessageDirection.Outbound) {
              return (
                <Text key={message.date.toString()} color="#22FF22">
                  {'$ '}
                  <Code whiteSpace="pre-wrap" color="#22FF22" bg="transparent">
                    {message.content}
                  </Code>
                </Text>
              );
            }
            if (message.action) {
              if (message.action.type === 'slotset') {
                return (
                  <Text color="white" key={message.date.toString()}>
                    <b>[SLOT] </b>
                    {`${message.action.value.key} -> ${JSON.stringify(
                      message.action.value.value,
                    )}`}
                  </Text>
                );
              } else if (message.action.type === 'dispatch') {
                try {
                  if (message.action.value.text) {
                    return (
                      <Text color="white" key={message.date.toString()}>
                        <b>[DEBUG] text: </b>
                        {message.action.value.text}
                      </Text>
                    );
                  } else if (message.action.value.link) {
                    return (
                      <Text color="white" key={message.date.toString()}>
                        <b>[DEBUG] link: </b>
                        <a href={message.action.value.link}>
                          {message.action.value.link}
                        </a>
                      </Text>
                    );
                  } else if (message.action.value.image) {
                    return (
                      <Fragment key={message.date.toString()}>
                        <b>[DEBUG] image: </b>
                        <Image
                          src={message.action.value.image}
                          alt="image"
                          maxW="200px"
                        />
                      </Fragment>
                    );
                  }
                } catch (e) {
                  // TODO: Handle this
                }
                return (
                  <TerminalContent key={message.date.toString()}>
                    {typeof message.action.value === 'string'
                      ? message.action.value
                      : JSON.stringify(message.action.value)}
                  </TerminalContent>
                );
              } else {
                return (
                  <Text color="white" key={message.date.toString()}>
                    <b>[DEBUG] </b>
                    {typeof message.action.value === 'string'
                      ? message.action.value
                      : JSON.stringify(message.action.value)}
                  </Text>
                );
              }
            }
            if (message.error) {
              return (
                <Code
                  key={message.date.toString()}
                  color="#F00"
                  whiteSpace="pre-wrap"
                >
                  {message.error}
                </Code>
              );
            }

            return (
              <TerminalContent key={message.date.toString()} {...message}>
                {message.content}
              </TerminalContent>
            );
          })
          .map((elem, index) => (
            <Flex key={index.toString()} align="center">
              {elem}
              {index === 0 && terminalState.isWaitingForInput && (
                <TerminalPrompt />
              )}
            </Flex>
          ))}
      </Flex>
    </Box>
  );
};

export default Terminal;
