import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfirmDelete } from 'chakra-confirm';

import {
  Stack,
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  AccordionIcon,
  AccordionPanel,
  HStack,
  Input,
  Spinner,
  Text,
} from '@chakra-ui/react';
import useDispatch from '@hooks/useDispatch';
import { setDisplayedFileId, setIsOpen } from '@slices/fileSlices';
import {
  projectFilesSelector,
  filesSelector,
  projectsSelector,
  displayedFileIdSelector,
  userSelector,
  fileStructureSelector,
  selectedProjectSelector,
} from '@selectors';
import config from '@config';
import User from '@/types/User';

import FileOptionsMenu from '../Editor/CodeEditor/FileOptionsMenu';
import ProjectTreeSkeleton from './ProjectTreeSkeleton';
import FileUsers from './FileUsers';
import FileIconSelector from './FileIconSelector';

import { selectedBoard } from '../Editor/BlocklyEditor/Blockly/helpers/board';
import Project from '@/types/Project';
import { ProjectType } from '@projectTypes/Project';

const sortFiles = (a: any, b: any) => {
  if (a.isFolder && b.isFolder) {
    return a.name > b.name ? 1 : -1;
  } else if (b.isFolder) {
    return 1;
  } else if (a.isFolder) {
    return -1;
  }
  return a.name > b.name ? 1 : -1;
};

const IS_FILE_ADD_ALLOWED_FOLDERS = [
  {
    name: 'database',
    contentType: 'json',
  },
];

const IS_FILE_TYPE_DISABLED_FOLDER_NAME = ['database'];
const IS_FILE_DELETABLE_FOLDER_NAME = ['database'];
const IS_FILE_NAME_HIDDEN = [config.mainXMLFileName];

function isNameShown(name: string, user: User, selectedProject: Project) {
  if (name === config.folderCreationFileName) {
    return false;
  }
  if (
    name === config.mainXMLFileName &&
    user.role != 'Admin' &&
    selectedProject.type === ProjectType.PLATFORMIO
  ) {
    return false;
  }
  return true;
}

const ProjectTree = () => {
  const dispatch = useDispatch();
  const fileState = useSelector(filesSelector);
  const projectState = useSelector(projectsSelector);
  const newFileSystem = useSelector(fileStructureSelector);
  const files = useSelector(projectFilesSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const displayedFileId = useSelector(displayedFileIdSelector);
  const [folderFilesNameEdit, setFolderFilesNameEdit] = useState({});
  const [newFileNames, setNewFileNames] = useState<Record<string, string>>({});
  const [openF, setOpenF] = useState(false);
  const user = useSelector(userSelector);
  const [folderMenu, setFolderMenu] = useState('');
  const [showBox, setShowBox] = useState(false);

  const confirmExit = useConfirmDelete({
    title: 'Are you sure you want exit without saving',
    body: 'All data will be lost',
    buttonText: 'Discard and exit',
  });

  const openFile = async (fileId) => {
    if (projectState.showProjectInfo && projectState.isNewProjectInfo) {
      if (!(await confirmExit())) {
        return;
      }
    }
    dispatch(setIsOpen({ isOpen: true, fileId }));
    dispatch(setDisplayedFileId(fileId));
  };

  const renderFileSystem = (
    item: any,
    isFileTypeDisabled = false,
    isFileDeletable = false,
    isFileNameEditable = false,
  ) => {
    if (item?.isFolder) {
      if (!isFileTypeDisabled) {
        isFileTypeDisabled = IS_FILE_TYPE_DISABLED_FOLDER_NAME.includes(
          item.name,
        );
        isFileDeletable = IS_FILE_DELETABLE_FOLDER_NAME.includes(item.name);
      }
      const folderFileAddAllowed = IS_FILE_ADD_ALLOWED_FOLDERS.find(
        (folders) => folders.name === item.name,
      );

      if (item) {
        return (
          <>
            <Box flexDir={'row'}>
              <AccordionItem
                id={item.id}
                key={item.name}
                border="none"
                backgroundColor={'#181818'}
                alignContent={'center'}
                textOverflow={'clip'}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexDir={'row'}
                  alignItems="center"
                  height={'24px'}
                >
                  <AccordionButton
                    _hover={{ background: '#2b2d2e', borderRadius: '8px' }}
                    _focus={{ borderWidth: 0 }}
                    paddingBottom={'2px'}
                    paddingTop={'2px'}
                    height={'24px'}
                    borderRadius={'8px'}
                    onMouseEnter={(e) => {
                      setFolderMenu(item.id);
                    }}
                    onMouseLeave={(e) => {
                      setFolderMenu('');
                    }}
                    flexDir="row"
                  >
                    <AccordionIcon color="white" margin={0} />
                    <Box
                      flex="1"
                      fontStyle={'normal'}
                      fontFamily={'-apple-system,BlinkMacSystemFont,sans-serif'}
                      fontSize="13px"
                      textAlign="left"
                      flexDir={'row'}
                      color="white"
                      onClick={() => {
                        setShowBox(!showBox);
                      }}
                      marginRight={'5'}
                      borderRadius={'8px'}
                    >
                      <Text noOfLines={1}>{item.name}</Text>
                    </Box>
                    {(displayedFileId === item.id ||
                      folderMenu === item.id) && (
                      <FileOptionsMenu currentFile={item} path={item.path} />
                    )}
                  </AccordionButton>
                </Box>

                <AccordionPanel
                  alignContent={'auto'}
                  border-top={0}
                  margin-bottom={'-2px'}
                  margin-top={'-2px'}
                  overflow={'unset !important'}
                  paddingBottom={'-2px'}
                  paddingTop={'-2px'}
                  position={'relative'}
                  top={'1px'}
                  borderRadius={'8px'}
                >
                  {Object.values(item)
                    .sort(sortFiles)
                    .map((i) => {
                      return renderFileSystem(
                        i,
                        isFileTypeDisabled,
                        isFileDeletable,
                        folderFilesNameEdit[item.name],
                      );
                    })}
                </AccordionPanel>
              </AccordionItem>
            </Box>
          </>
        );
      }
    } else if (item && item.name) {
      const fileName =
        isFileNameEditable && newFileNames[item.id]
          ? newFileNames[item.id]
          : item.name;
      const displayedFileName = isFileTypeDisabled
        ? fileName.split('.')[0]
        : fileName;
      const fileType = item.name.split('.')[1];

      return (
        <HStack
          width={config.sidebarWidth}
          key={item.id}
          justifyContent="left"
          borderRadius={'8px'}
        >
          {isFileNameEditable ? (
            <Input
              w="150px"
              value={displayedFileName}
              onChange={(event) => {
                let value = event.target.value;
                if (isFileTypeDisabled && fileType) {
                  value += `.${fileType}`;
                }
                newFileNames[item.id] = value;
                setNewFileNames({ ...newFileNames });
              }}
              size="sm"
              borderRadius="1px"
              borderColor="primary"
              color="primary"
            />
          ) : (
            isNameShown(item.name, user, selectedProject) && (
              <>
                <Box data-group width={'100%'}>
                  <AccordionItem
                    border={'transparent'}
                    alignContent={'left'}
                    padding-top={'-2px'}
                    borderRadius={'8px'}
                  >
                    <AccordionButton
                      _hover={{ background: '#2b2d2e', borderRadius: '8px' }}
                      _focus={{ borderWidth: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        openFile(item.id);
                        setOpenF(item.id);
                      }}
                      alignContent={'left'}
                      marginLeft={'0'}
                      backgroundColor={
                        displayedFileId === item.id ? '#37373c' : 'transparent'
                      }
                      height={'24px'}
                      border-color={'transparent'}
                      textAlign={'left'}
                      textColor={'white'}
                      fontSize={'13px'}
                      paddingBottom={'5px'}
                      paddingTop={'5px'}
                      paddingRight={0}
                      borderRadius={'8px'}
                      flexDir="row"
                      justifyContent="space-between"
                      text-overflow={'clip'}
                    >
                      <HStack>
                        <Box margin={'5px'}>
                          <FileIconSelector ext={item.name.split('.')[1]} />
                        </Box>
                        <Text noOfLines={1} maxWidth={'150px'}>
                          {item.name}
                        </Text>
                      </HStack>

                      <Box
                        display={displayedFileId === item.id ? 'block' : 'none'}
                        _groupHover={{ display: 'block' }}
                      >
                        <FileOptionsMenu currentFile={item} path={item.path} />
                      </Box>
                    </AccordionButton>
                  </AccordionItem>
                </Box>
              </>
            )
          )}
          <FileUsers fileId={item.id} />
        </HStack>
      );
    }
    return null;
  };

  const { fileSystem, maxDepth } = newFileSystem;
  const closeAll = Object.values(fileSystem).reduce(
    (acc, cur: any) =>
      acc ? acc : !!(cur.isFolder && Object.keys(cur).length > 10),
    false,
  );
  return (
    <Stack
      width={config.sidebarWidth}
      backgroundColor={'#181818'}
      overflow={'unset !important'}
    >
      {fileState.isLoading && files.length === 0 ? (
        <ProjectTreeSkeleton />
      ) : (
        <>
          {fileState.isLoading ? (
            <Spinner ml="20px" size="xs" />
          ) : (
            <Box h="12px" />
          )}
          <Accordion
            key={closeAll ? 1 : 0}
            allowMultiple
            defaultIndex={
              closeAll
                ? []
                : Array.from({ length: maxDepth }).map((v, index) => index)
            }
            overflow={'unset !important'}
          >
            {Object.values(fileSystem)
              .sort(sortFiles)
              .map((item) => {
                return renderFileSystem(item);
              })}
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export default ProjectTree;
