import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { pythonSelector, stateSelector } from '@selectors';
import config from '@config';
import { setShowProjectModal } from '@slices/projectSlices';
import isWindowAvailable from '@helpers/isWindowAvailable';
import useDispatch from '@hooks/useDispatch';

import CompileTypeModal from './Modals/CompileTypeModal';
import UserAccountModal from './Modals/UserAccountModal';
import QrCode from './Modals/QrCode';
import SelectProjectModal from './Modals/SelectProjectModal';
import { TestResultsModal } from './Terminal/TestResultsModal';
import SelectDeviceModal from './Modals/SelectDeviceModal';
import CommitModal from './Modals/CommitModal';

const ModalContainer = (props: { isGuest?: boolean }) => {
  const { isGuest } = props;
  const dispatch = useDispatch();
  const state = useSelector(stateSelector);
  const [hideSelectProjectModal, setHideSelectProjectModal] = useState(false);
  const pythonState = useSelector(pythonSelector);
  const isRedirectUrl = isWindowAvailable() && [
    config.templateSlug,
    config.studentProjectId,
  ].some((url) => window.location.search.includes(url));

  const modalProps = useDisclosure();

  const onClose = () => {
    setHideSelectProjectModal(true);
    dispatch(setShowProjectModal(false));
  };

  useEffect(() => {
    if (pythonState.tests.length) {
      modalProps.onOpen();
    } else {
      modalProps.onClose();
    }
  }, [pythonState.tests]);

  if (!state.user.isLoggedIn) {
    return <UserAccountModal isGuest={isGuest} />;
  } else if (state.terminal.lastCompileId) {
    // TODO: fix this
    const url = `https://builder2.stemi.education/api/binary/${state.terminal.lastCompileId}`;
    return (
      <QrCode
        key={state.terminal.lastCompileId}
        data={url}
        lastCompileId={state.terminal.lastCompileId}
      />
    );
  } else if (state.terminal.shouldCompileModal) {
    return <CompileTypeModal key={state.terminal.shouldCompileModal ? 1 : 0} />;
  } else if (state.device.showDeviceModal) {
    return <SelectDeviceModal />;
  } else if (state.commit.showCommitModal) {
    return <CommitModal />;
  } else if (
    (!isRedirectUrl && !hideSelectProjectModal) ||
    state.project.showProjectModal
  ) {
    return <SelectProjectModal onClose={onClose} />;
  } else if (pythonState.tests.length > 0) {
    return (
      <TestResultsModal modalProps={modalProps} tests={pythonState.tests} />
    );
  }
  return null;
};

export default ModalContainer;
