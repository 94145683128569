import { useEffect } from 'react';

import { saveFiles } from '@slices/fileSlices';
import useDispatch from '@hooks/useDispatch';
import {
  runFile,
  trainProject as trainProjectAction,
} from '@slices/terminalSlices';
import Project from '@projectTypes/Project';

function useShortcuts(displayedFileId: string, selectedProject: Project) {
  const dispatch = useDispatch();

  const saveFile = (fileId: string) => {
    dispatch(saveFiles([fileId]));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && displayedFileId) {
        switch (event.key) {
          case 's':
            event.preventDefault();
            saveFile(displayedFileId);
            return;
          case 'r':
            event.preventDefault();
            dispatch(
              runFile({
                projectId: selectedProject.id,
                fileId: displayedFileId,
              }),
            );
            return;
        }
        if (event.key === 's') {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}

export default useShortcuts;
