import { useEffect, useRef, useState } from 'react';

import getFileContentError from '@helpers/getFileContentError';
import File from '@projectTypes/File';
import { FileContentError } from '@projectTypes/Error';

const useCodeError = (displayedFile: File) => {
  const validateFileTimeout = useRef<NodeJS.Timeout>();
  const [error, setError] = useState<FileContentError>(null);

  const validateFile = async () => {
    const contentError = await getFileContentError(
      displayedFile.newContent,
      displayedFile.contentType,
    );
    setError(contentError);
  };

  useEffect(() => {
    if (validateFileTimeout.current) {
      clearTimeout(validateFileTimeout.current);
    }
    validateFileTimeout.current = setTimeout(validateFile, 250);
  }, [displayedFile.newContent]);

  return error;
};

export default useCodeError;
