import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

import { ProjectType } from '@/types/Project';
import File from '@projectTypes/File';
import { editorSelector, selectedProjectSelector } from '@selectors';
import useResizeObserver from '@hooks/useResizeObserver';
import { Box, Flex } from '@chakra-ui/react';
import useShortcuts from '@hooks/useShortcuts';
const MonacoEditor = dynamic(import(`@monaco-editor/react`) as any, {
  ssr: false,
}) as any;

import useEditorFeatures from './hooks/useEditorFeatures';
import WebDevEditor from '../WebDevEditor/WebDevEditor';

const LINE_HEIGHT = 18;

interface CodeEditorProps {
  displayedFile: File;
  language: string;
  width: number;
  onChange: (value: any) => void;
  fontSize: number;
}

const CodeEditor = (props: CodeEditorProps) => {
  const { displayedFile, language, width, onChange, fontSize } = props;
  const selectedProject = useSelector(selectedProjectSelector);

  useResizeObserver('.monaco-mouse-cursor-text');
  // eslint-disable-next-line no-unused-vars
  const isWebProject = selectedProject.type === ProjectType.WEB;
  const adjustedCodeWidth = isWebProject ? '50%' : '100%';

  const [_minHeight, setMinHeight] = useState(500);

  const editorState = useSelector(editorSelector);

  const [editorDidMount] = useEditorFeatures(displayedFile);

  useShortcuts(displayedFile?.id, selectedProject);

  useEffect(() => {
    setMinHeight(
      displayedFile.newContent.split(/\r\n|\r|\n/).length * LINE_HEIGHT * 2,
    );
  }, [displayedFile.fileCounter, displayedFile.id]);

  return (
    <>
      <Flex flexDir="row">
        <MonacoEditor
          key={width}
          width={
            isWebProject
              ? adjustedCodeWidth
              : editorState.isSmall
              ? '100%'
              : `${width - 16}px`
          }
          height="100vh"
          language={language}
          theme="vs-dark"
          value={displayedFile.newContent}
          onChange={onChange}
          onMount={editorDidMount}
          options={{
            scrollbar: {
              vertical: 'hidden',
            },
            wordWrap: 'on',
            lineHeight: fontSize * 1.5,
            fontSize,
            minimap: {
              enabled: false,
            },
            padding: { top: 8 },
          }}
        />
        {isWebProject && (
          <Box flex={1}>
            <WebDevEditor 
            displayedFile={displayedFile}/>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default CodeEditor;
