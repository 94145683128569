import React, { useState } from 'react';
import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import {
  isDesktopLiveSelector,
  isHexapodReadySelector,
  selectedProjectIdSelector,
} from '@selectors';
import { flashEsp, uploadUsingDesktopApp } from '@slices/terminalSlices';
import isSerialAvailable from '@/helpers/isSerialAvailable';
import useDispatch from '@hooks/useDispatch';

export interface QrCodeProps {
  data: string;
  lastCompileId?: string;
}

const QrCode: React.FC<QrCodeProps> = ({ data, lastCompileId }) => {
  const [isOpen, setIsOpen] = useState(true);
  const isDesktopLive = useSelector(isDesktopLiveSelector);
  const isHexapodReady = useSelector(isHexapodReadySelector);
  const dispatch = useDispatch();
  const selectedProjectId = useSelector(selectedProjectIdSelector);

  const onClose = () => {
    setIsOpen(false);
  };

  const uploadUsingDesktopAppHelper = async () => {
    onClose();
    await dispatch(uploadUsingDesktopApp());
  };

  const flashOverUsb = () => {
    dispatch(flashEsp({ projectId: selectedProjectId, hardReset: false }));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mobile app upload</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QRCode
            value={data}
            size={400}
            level="H"
            includeMargin
            bgColor="#fff"
            fgColor="#000"
            imageSettings={{
              src: '/images/logo.png',
              x: null,
              y: null,
              height: 80,
              width: 80,
              excavate: true,
            }}
          />
          {isDesktopLive && lastCompileId && (
            <Button
              onClick={uploadUsingDesktopAppHelper}
              isDisabled={!isHexapodReady}
            >
              {isHexapodReady
                ? 'Upload using desktop app'
                : 'Connect Hexapod and select BAUD and PORT'}
            </Button>
          )}
        </ModalBody>

        <ModalFooter>
          {isSerialAvailable() && (
            <Button colorScheme="blue" mr={3} onClick={flashOverUsb}>
              USB upload
            </Button>
          )}
          <Button variant='ghost' onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QrCode;
