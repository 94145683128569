import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Toolbar from '@components/Toolbar';
import {
  displayedFileSelector,
  editorSelector,
  selectedProjectSelector,
} from '@selectors';
import { rightSidebarSelector } from '@/selectors/globalSelectors';
import config from '@config';

import EditorField from './EditorField';
import OpenFilesNavigation from './OpenFilesNavigation';
import ModalEditorContainer from './ModalEditorContainer';
import useShortcuts from '@hooks/useShortcuts';

const EditorContainer = () => {
  const editor = useSelector(editorSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const displayedFile = useSelector(displayedFileSelector);
  const isRightSidebarOpen = useSelector(rightSidebarSelector);

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      bg="background"
      color="#FFF"
      backgroundColor={'#181818'}
    >
      {editor.isSmall ? (
        <>
          {selectedProject?.name && (
            <Text
              fontSize="xl"
              pos="absolute"
              zIndex={10}
              padding="10px"
              background="#181818"
              backgroundColor={'#181818'}
              right="0px"
              bottom="0px"
            >
              {selectedProject?.name}
            </Text>
          )}
          <Toolbar isSmall />
        </>
      ) : (
        <HStack minH="40px">
          <OpenFilesNavigation />
          <Toolbar />
        </HStack>
      )}

      <Box
        id="editor"
        flex="1"
        bg="#181818"
        overflow="auto"
        transition="0.4s"
        w={
          isRightSidebarOpen
            ? `calc(100% - ${config.rightSidebarWidth}px)`
            : '100%'
        }
      >
        <EditorField />
      </Box>
      <ModalEditorContainer />
    </Flex>
  );
};

export default EditorContainer;
