import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import {
  selectedProjectSelector,
  selectedProjectTemplateSlugSelector,
} from '@selectors';
import heartBeatService from '@services/HeartBeatService';
import { useOnEditorInit } from '@hooks/useOnAppInit';
import { ShowcaseInfoMain } from '@components/Main/Answers';
import { IDE } from '@components/Main/IDE';
import { ProjectType } from '@projectTypes/Project';
import { CustomPage } from '@/types';
import useOnEditorExit from '@hooks/useOnEditorExit';
import TopBanner from '@components/TopBanner';
import ModalContainer from '@components/ModalContainer';

import { useParam } from '@hooks/useParam';

const Home: CustomPage = () => {
  useOnEditorInit();
  useOnEditorExit();

  const selectedProject = useSelector(selectedProjectSelector);
  const embedShowcase = useParam('embedShowcase') === 'true';
  const isGuest = useParam('isGuest') === 'true';
  const templateSlug = useSelector(selectedProjectTemplateSlugSelector);

  const [interval, appointInterval] = useState<any>();

  useEffect(() => {
    if (interval !== undefined && interval !== null) {
      clearInterval(interval);
      appointInterval(null);
    }
    appointInterval(
      setInterval(() => {
        if (document.hasFocus()) {
          heartBeatService.heartBeat(
            `/editor/${(window as any).templateSlug}/`,
          );
        }
      }, 60000),
    );
  }, []);

  useEffect(() => {
    (window as any).templateSlug = templateSlug;
  }, [templateSlug]);

  return (
    <>
      <Head>
        <title>STEMI Online IDE</title>
      </Head>

      <ModalContainer isGuest={isGuest} />
      <TopBanner />
      {embedShowcase || selectedProject?.type === ProjectType.ANSWERS ? (
        <ShowcaseInfoMain project={selectedProject} />
      ) : (
        <IDE />
      )}
    </>
  );
};

Home.persist = true;

export default Home;
